import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import { useParams, useHistory } from "react-router-dom";
import moment from "moment-timezone";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Button,
  Grid,
  Chip,
  Typography, Tabs, Tab, Box,
} from "@material-ui/core";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import {
  setCurrentPage, getA2ECampaignMetaById, setA2ECustomDateRange, setA2EAlias, setA2EReportTab,
  getA2EDataSummary, getA2EDataByDate, getA2EDataByCreative, getA2EDataByQuiz,
} from "actions";
import LocalCalendar from "components/Calendar/LocalCalendar";
import CampaignSummary from "components/A2E/CampaignSummary";
import LineChartA2eCampaign from "components/Charts/LineChartA2eCampaign";
import A2EDataTable from "components/Table/A2EDataTable";
import * as C from "components";
import * as V from "styles/variables";
import LabelConfig from "utils/a2e/LabelConfig";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={1}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) =>
  createStyles({
    dataBtn: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2),
      height: theme.spacing(4),
      color: V.btnLabelColor,
    },
    gridSection: {
      marginTop: theme.spacing(1),
    },
    pageSection: {
      marginTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    table: {
      minHeight: "500px",
      overflowY: "scroll",
    },
    loaderBox: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: 280,
    },
    loaderBarImg: {
      width: 40,
    },
    selectTxt: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: 280,
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
      },
    },
  })
);

// const byDateLegend = [
//   "Imp", "Watch", "Watched", "Answered", "Correct", "Banner Click", "Hint Click"
// ];

// const mockData = [
//   {
//     name: 'Email',
//     type: 'line',
//     stack: 'Total',
//     data: [120, 132, 101, 134, 90, 230, 210]
//   },
//   {
//     name: 'Union Ads',
//     type: 'line',
//     stack: 'Total',
//     data: [220, 182, 191, 234, 290, 330, 310]
//   },
//   {
//     name: 'Video Ads',
//     type: 'line',
//     stack: 'Total',
//     data: [150, 232, 201, 154, 190, 330, 410]
//   },
// ];

const CampaignReport = ({
  setCurrentPage,
  pageTopLoader,
  getA2ECampaignMetaById,
  campaignSelect,
  setA2ECustomDateRange,
  setA2EAlias,
  customDateRange,
  setA2EReportTab,
  reportTab,
  getA2EDataSummary,
  getA2EDataByDate,
  getA2EDataByCreative,
  getA2EDataByQuiz,
  dataSummary,
  dataByDate,
  dataByCreative,
  dataByQuiz,
  tabDataLoader,
  alias,
}) => {
  const classes = useStyles();
  const params = useParams();
  const [btnStateDataFetch, setBtnState] = useState(true);
  const [chartState, setChartState] = useState(true);
  const [chartData, setChartData] = useState([]);
  const [legendData, setLegendData] = useState([]);
  const [defaultDateRange, setDefaultDateRagne] = useState([]);
  const [campaignMeta, setCampaignMeta] = useState({});
  const [tabValue, setTabValue] = React.useState(0);

  useEffect(() => {
    setCurrentPage(window.location.pathname);
    if (params.cid !== undefined) {
      getA2ECampaignMetaById(params.cid);
    }
  }, []);

  useEffect(() => {
    if (dataByDate.length > 0) {
      const keys = Object.keys(dataByDate[0]).filter(key => key !== 'date');
      const chartSeries = keys.map(key => ({
        name: LabelConfig.metricLabel[key],
        type: 'line',
        stack: 'Total',
        data: dataByDate[0][key]
      }));
      setChartData(chartSeries);
      const legendData = keys.map(key => (
        LabelConfig.metricLabel[key]
      ));
      setLegendData(legendData);
    }
  }, [dataByDate]);

  useEffect(() => {
    if (campaignSelect.length > 0) {
      const campaignMetaObj = {
        name: campaignSelect[0]["alias"],
        client: campaignSelect[0]["answer_producer_alias"],
        startDate: moment(campaignSelect[0]["start_datetime"]).tz("Asia/Tokyo").format('YYYY-MM-DD'),
        endDate: moment(campaignSelect[0]["end_datetime"]).tz("Asia/Tokyo").format('YYYY-MM-DD'),
      }
      setDefaultDateRagne([
        moment(campaignMetaObj.startDate).tz("Asia/Tokyo").format("YYYY-MM-DD"),
        moment(campaignMetaObj.endDate).tz("Asia/Tokyo").format("YYYY-MM-DD")
      ]);
      setA2ECustomDateRange([
        moment(campaignMetaObj.startDate).tz("Asia/Tokyo").format("YYYY-MM-DD"),
        moment(campaignMetaObj.endDate).tz("Asia/Tokyo").format("YYYY-MM-DD")
      ]);
      setCampaignMeta(campaignMetaObj);
      setA2EAlias(campaignSelect[0]["alias"]);
    }
  }, [campaignSelect]);

  useEffect(() => {
    // console.log(`LOG: Fetch data for ${reportTab}`)
  }, [reportTab]);

  useEffect(() => {
    if (campaignMeta.startDate && campaignMeta.endDate) {
      getA2EDataSummary(params.cid);
    }
  }, [campaignMeta]);

  const setRange = (newValue) => {
    const startDate = moment(newValue[0]).format("YYYY-MM-DD");
    const endDate = moment(newValue[1]).format("YYYY-MM-DD");
    setA2ECustomDateRange([startDate, endDate]);
    setBtnState(false);
  };

  const fetchData = () => {
    getA2EDataSummary(params.cid);
    setBtnState(!btnStateDataFetch);
    // this data fetch is done in saga
    // switch (reportTab) {
    //   case "byDate":
    //     getA2EDataByDate(campaignMeta.id);
    //     break;
    //   case "byCreative":
    //     getA2EDataByCreative(campaignMeta.id);
    //     break;
    //   case "byQuiz":
    //     getA2EDataByQuiz(campaignMeta.id);
    //     break;
    //   default:
    // }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    switch (newValue) {
      case 0:
        setA2EReportTab("byDate");
        getA2EDataByDate(campaignMeta.id);
        break;
      case 1:
        setA2EReportTab("byCreative");
        getA2EDataByCreative(campaignMeta.id);
        break;
      case 2:
        setA2EReportTab("byQuiz");
        getA2EDataByQuiz(campaignMeta.id);
        break;
      default:
    }
  };

  return (
    <C.ContentWrapper>
      <Grid
        container
        spacing={1}
        className={classes.gridSection}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12} md={12} lg={10}>
          <Grid
            container
            spacing={1}
            className={classes.gridSection}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item>
              <div>
                配信名: {campaignMeta.name ? campaignMeta.name : "--"}
              </div>
            </Grid>
            <Grid item>
              <Grid container>
                <Grid item>
                  {campaignMeta.startDate &&
                    <LocalCalendar
                      setRange={setRange}
                      setBtnState={setBtnState}
                      defaultRange={defaultDateRange}
                      newRange={customDateRange}
                      mDate={campaignMeta.startDate}
                    />
                  }
                </Grid>
                <Grid item>
                  <Button
                    className={classes.dataBtn}
                    variant="contained"
                    color="primary"
                    onClick={() => fetchData()}
                  >
                    GO
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <CampaignSummary campaignMeta={campaignMeta} dataSummary={dataSummary} />

      <Grid
        container
        spacing={0}
        className={classes.gridSection}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12} md={12} lg={10}
          style={{ borderBottom: '1px solid #ccc' }}
        >
          <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab label="日別" {...a11yProps(0)} />
            <Tab label="クリエイティブ別" {...a11yProps(1)} disabled />
            <Tab label="クイズ別" {...a11yProps(2)} disabled />
          </Tabs>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        className={classes.gridSection}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12} md={12} lg={10}>
          {/* 日別 */}
          <TabPanel value={tabValue} index={0}>
            {pageTopLoader || tabDataLoader ? (
              <div className={classes.loaderBox}>
                <img
                  className={classes.loaderBarImg}
                  src={`/static/images/loader-bar.gif`}
                />
              </div>
            ) : (
              dataByDate[0] !== undefined && dataByDate[0]["date"].length > 0 ? (
                <LineChartA2eCampaign
                  legend={legendData}
                  lblSaveImage={"キャンペーン名"}
                  xAisData={dataByDate[0]["date"]}
                  seriesData={chartData}
                  chartType={"line"}
                  height={350}
                />
              ) : (
                <div className={classes.selectTxt}>
                  <Chip
                    label={"データ取得中..."}
                    style={{
                      color: V.textColor,
                      backgroundColor: V.placeholderColor,
                    }}
                  />
                </div>
              )
            )}

            <A2EDataTable
              title=""
              noDataText={tabDataLoader ? "ロード中.." : ""}
              trendData={dataByDate}
              dateRange={customDateRange}
              loading={tabDataLoader}
              exportTitle={`A2Eレポート: ${alias}`}
              legend={legendData}
            />
          </TabPanel>

          {/* クリエイティブ別 */}
          <TabPanel value={tabValue} index={1}>
            <div className={classes.selectTxt}>
              <Chip
                label="データがありません"
                style={{
                  color: V.textColor,
                  backgroundColor: V.placeholderColor,
                }}
              />
            </div>
          </TabPanel>

          {/* クイズ別 */}
          <TabPanel value={tabValue} index={2}>
            <div className={classes.selectTxt}>
              <Chip
                label="データがありません"
                style={{
                  color: V.textColor,
                  backgroundColor: V.placeholderColor,
                }}
              />
            </div>
          </TabPanel>
        </Grid>
      </Grid>
    </C.ContentWrapper>
  );
};

CampaignReport.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  pageTopLoader: state.page.pageTopLoader,
  campaignSelect: state.a2e.campaignSelect,
  customDateRange: state.a2e.customDateRange,
  reportTab: state.a2e.reportTab,
  dataSummary: state.a2e.dataSummary,
  dataByDate: state.a2e.dataByDate,
  dataByCreative: state.a2e.dataByCreative,
  dataByQuiz: state.a2e.dataByQuiz,
  tabDataLoader: state.a2e.tabDataLoader,
  alias: state.a2e.alias,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentPage: (param) => dispatch(setCurrentPage(param)),
  getA2ECampaignMetaById: (param) => dispatch(getA2ECampaignMetaById(param)),
  setA2ECustomDateRange: (param) => dispatch(setA2ECustomDateRange(param)),
  setA2EAlias: (param) => dispatch(setA2EAlias(param)),
  setA2EReportTab: (param) => dispatch(setA2EReportTab(param)),
  getA2EDataSummary: (param) => dispatch(getA2EDataSummary(param)),
  getA2EDataByDate:(param) => dispatch(getA2EDataByDate(param)),
  getA2EDataByCreative:(param) => dispatch(getA2EDataByCreative(param)),
  getA2EDataByQuiz:(param) => dispatch(getA2EDataByQuiz(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(CampaignReport));
