import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import * as H from "helper";
import StatBoxSlim from "components/Stats/StatBoxSlim";

const useStyles = makeStyles((theme) =>
  createStyles({
    box: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(1),
      borderRight: "1px solid #dcdcdc",
    },
    boxLast: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(1),
    },
    title: {
      display: "block",
      height: 32,
      fontSize: 14,
    },
    value: {
      paddingTop: 24,
      paddingBottom: 8,
      fontSize: 14,
      marginTop: 24,
    },
  })
);

const Summary = ({
  adSummaryData,
}) => {
  const classes = useStyles();
  useEffect(() => {}, []);

  return (
    <Grid container spacing={1}>
      <Grid item sm={12} md={12}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <StatBoxSlim
              title="インプレッション"
              value={adSummaryData.impression !== undefined ? adSummaryData.impression : "-"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <StatBoxSlim
              title="クリック"
              value={adSummaryData.click !== undefined ? adSummaryData.click : "-"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <StatBoxSlim
              title="CTR"
              value={adSummaryData.ctr !== undefined ? adSummaryData.ctr : "-"}
              suffix="%"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <StatBoxSlim
              title="インプレッション・ユニーク"
              value={adSummaryData.impressionUni !== undefined ? adSummaryData.impressionUni : "-"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <StatBoxSlim
              title="クリック・ユニーク"
              value={adSummaryData.clickUni !== undefined ? adSummaryData.clickUni : "-"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={2}>
            <StatBoxSlim
              title="CTR・ユニーク"
              value={adSummaryData.ctrUni !== undefined ? adSummaryData.ctrUni : "-"}
              suffix="%"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

Summary.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  adSummaryData: state.pictree.adSummary,
});

const mapDispatchToProps = (dispatch) => ({
  //setCurrentModule: (param) => dispatch(setCurrentModule(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(Summary));
