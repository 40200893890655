import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import moment from "moment";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Typography,
  Breadcrumbs,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import GetAppIcon from "@material-ui/icons/GetApp";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import {
  setCurrentPage,
  setTxHistoryDataFilter,
  setFilterDrawerState,
  setPointExchangeDataPicTreeNumber,
  getInGameItemHistoryCsvData,
  setNftTxCsvDownloader,
  getPointExchangeDataPicTree,
} from "actions";
import * as C from "components";
import * as V from "styles/variables";
import * as H from "helper";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      "& > *": {
        //borderBottom: 'unset',
      },
    },
    fillRemailSpace: {
      flex: "auto",
    },
    sectionTop: {
      marginTop: -16,
      marginBottom: 8,
      [theme.breakpoints.down("sm")]: {
        marginTop: 8,
      },
    },
    breadcrumbs: {
      marginTop: 16,
      minHeight: 30,
    },
    dataBtn: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2),
      height: theme.spacing(4),
      color: V.btnLabelColor,
    },
    autoSelect: {
      marginTop: 8,
      marginLeft: 16,
    },
    gridSection: {
      marginTop: theme.spacing(1),
    },
    pageSection: {
      marginTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
    table: {
      minHeight: "500px",
      overflowY: "scroll",
    },
    statsSection: {
      minHeight: 60,
      padding: theme.spacing(2),
      borderRadius: 4,
    },
    statBox: {
      marginTop: 0,
      minHeight: 60,
      paddingLeft: 8,
      borderRight: "1px solid #dcdcdc",
      "&:last-child": {
        borderRight: "none",
      },
    },
    statTitle: {
      fontSize: 13,
    },
    statValue: {
      fontSize: 15,
      paddingTop: 8,
      fontWeight: 500,
    },
    filterBtn: {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2),
      height: theme.spacing(4),
    },
    titleDl: {
      marginRight: 16,
      color: "#000",
    },
  })
);

function Row(props) {
  const { index, row, pageNumber } = props;
  const classes = useStyles();
  const number = pageNumber > 1 ? 50*(pageNumber-1) + Number(index) + 1 : Number(index) + 1;
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell align="left">{number}</TableCell>
        <TableCell align="center">{row.playerId}</TableCell>
        <TableCell align="center">{row.player}</TableCell>
        <TableCell align="center">{row.goods}</TableCell>
        <TableCell align="center">{row.point}</TableCell>
        <TableCell align="center">{row.created_at}</TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const PicTreePointExHistory = ({
  setCurrentPage,
  currentPage,
  txHistoryDataFilter,
  setTxHistoryDataFilter,
  pageTopLoader,
  setFilterDrawerState,
  filterDrawerState,
  setPointExchangeDataPicTreeNumber,
  pageNumber,
  getInGameItemHistoryCsvData,
  itemTxCsvData,
  nftTxCsvLoader,
  dateRangePicTree,
  getPointExchangeDataPicTree,
  pointExchangeData,
}) => {
  const classes = useStyles();
  const [btnStateDataFetch, setBtnState] = useState(true);
  const [gameLabel, setGameLabel] = useState("");
  const [targetValue, setTargetValue] = useState({ name: "" });
  const [txRowHisotryData, setTxRowHisotryData] = useState([]);
  const [noData, setNodata] = useState(false);
  const [initialDataCall, setInitialDataCall] = useState(true);
  const [totalPageNum, setTotalPageNum] = useState(0);
  const csvLinkRef = useRef(null);

  useEffect(() => {
    setCurrentPage(window.location.pathname);
  }, []);

  useEffect(() => {
    let game = window.location.pathname.split("/");
    let target = game[2];
    setGameLabel(H.ContentsHelper.getContentsName(target));
  }, [window.location.pathname]);

  useEffect(() => {
    if (dateRangePicTree[0] !== null && dateRangePicTree[1]!== null
      && initialDataCall) {
      getPointExchangeDataPicTree("goods");
      setInitialDataCall(false);
    }
  }, [dateRangePicTree]);

  // useEffect(() => {
  //   if (itemTxCsvData.length > 0) {
  //     csvLinkRef.current.link.click();
  //     setNftTxCsvData([]);
  //   }
  // }, [itemTxCsvData]);

  useEffect(() => {
    if (pointExchangeData.goods === undefined) {
      return;
    };
    if (pointExchangeData.goods.length > 0) {
      let dataRows = [];
      _.each(pointExchangeData.goods, function (dataRow) {
        let rowObj = {
          playerId: dataRow.playerId,
          player: dataRow.player,
          goods: dataRow.goods,
          point: dataRow.point,
          created_at: dataRow.date,
        };
        dataRows.push(rowObj);
        rowObj = {};
      });
      setTxRowHisotryData(dataRows);
      const pageCount = pointExchangeData.summary.count / 50;
      setTotalPageNum(Math.ceil(pageCount));
      setNodata(false);
    } else {
      setNodata(true);
    }
  }, [pointExchangeData]);

  const handlePageChange = (event, value) => {
    setPointExchangeDataPicTreeNumber(value);
    getPointExchangeDataPicTree(value);
  };

  const showData = (value) => {
    if (value === "--") {
      return value;
    }
    if (isNaN(value)) {
      return 0;
    }
    return H.FormatNumStrHelper.formatCommaThousand(value);
  };

  return (
    <C.ContentWrapper>
      <Grid
        container
        direction="row"
        className={classes.sectionTop}
        justifyContent="space-between"
      >
        <Grid item>
          <Breadcrumbs separator="›" className={classes.breadcrumbs}>
            <Typography>{gameLabel}</Typography>
            <Typography>ポイント交換履歴</Typography>
            <Typography>グッズ</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item className={classes.fillRemailSpace}></Grid>
        <Grid item>
          <C.CalendarRangePicker mDate={"2025/02/22"} page="pictree/point-exchange-history/goods" pictree />
        </Grid>
      </Grid>

      <Grid
        container
        spacing={0}
        className={classes.gridSection}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12} md={12} lg={10}>
          <Paper className={classes.statsSection}>
            <Grid
              container
              direction="row"
              className={classes.gridSection}
              justifyContent="flex-start"
            >
              {/* <Grid
                className={classes.statBox}
                item
                xs={12}
                sm={6}
                md={4}
                lg={2}
              >
                <Grid
                  container
                  direction="row"
                  className={classes.gridSection}
                  justifyContent="space-between"
                >
                  <Grid item>
                    <div className={classes.statTitle}>合計件数:</div>
                  </Grid>
                  <Grid item>
                    {nftTxCsvLoader && (
                      <CircularProgress className={classes.titleDl} size={20} />
                    )}
                    {!nftTxCsvLoader && (
                      <>
                        <IconButton
                          onClick={() => getInGameItemHistoryCsvData(targetValue)}
                          className={classes.titleDl}
                          size="small"
                        >
                          <GetAppIcon />
                        </IconButton>
                      </>
                    )}
                    <CSVLink
                      data={itemTxCsvData}
                      filename={`InGameアイテム取引詳細データ_${gameLabel}.csv`}
                      className="hidden"
                      ref={csvLinkRef}
                      target="_blank"
                    />
                  </Grid>
                </Grid>
                <div className={classes.statValue}>
                  {pointExchangeData.summary !== undefined &&
                    showData(pointExchangeData["summary"]["count"])
                  }
                </div>
              </Grid> */}
              <Grid
                className={classes.statBox}
                item
                xs={12}
                sm={6}
                md={4}
                lg={2}
              >
                <div className={classes.statTitle}>ポイント総額:</div>
                <div className={classes.statValue}>
                  {pointExchangeData.summary !== undefined &&
                    showData(pointExchangeData["summary"]["point"])
                  }
                </div>
              </Grid>
              <Grid
                className={classes.statBox}
                item
                xs={12}
                sm={6}
                md={4}
                lg={2}
              >
                <div className={classes.statTitle}>交換件数:</div>
                <div className={classes.statValue}>
                  {pointExchangeData.summary !== undefined &&
                    showData(pointExchangeData["summary"]["count"])
                  }
                </div>
              </Grid>
              <Grid
                className={classes.statBox}
                item
                xs={12}
                sm={6}
                md={4}
                lg={2}
              >
                <div className={classes.statTitle}>交換者数:</div>
                <div className={classes.statValue}>
                  {pointExchangeData.summary !== undefined &&
                    showData(pointExchangeData["summary"]["player"])
                  }
                </div>
              </Grid>
              <Grid
                className={classes.statBox}
                item
                xs={12}
                sm={6}
                md={4}
                lg={2}
              >
                <div className={classes.statTitle}>グッズ件数:</div>
                <div className={classes.statValue}>
                  {pointExchangeData.summary !== undefined &&
                    showData(pointExchangeData["summary"]["goods"])
                  }
                </div>
              </Grid>
              <Grid
                className={classes.statBox}
                item
                xs={12}
                sm={6}
                md={4}
                lg={2}
              >
                {/* <div className={classes.statTitle}>交換最多アイテム:</div>
                <div className={classes.statValue}>
                  {pointExchangeData.summary !== undefined &&
                    showData(pointExchangeData["summary"]["goodsItem"])
                  }
                </div> */}
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        className={classes.gridSection}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item xs={12} md={12} lg={10}>
          <TableContainer component={Paper} className={classes.tableContainer}>
            <Table
              stickyHeader
              aria-label="collapsible table"
              className={classes.table}
            >
              <TableHead className={classes.tableHead}>
                <TableRow>
                  <TableCell align="left">#</TableCell>
                  <TableCell align="center">プレイヤーID</TableCell>
                  <TableCell align="center">プレイヤー名</TableCell>
                  <TableCell align="center">交換グッズ</TableCell>
                  <TableCell align="center">報酬コイン額</TableCell>
                  <TableCell align="center">交換日時（JST）</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {pageTopLoader && (
                  <TableRow>
                    <TableCell align="center" colSpan={9}>
                      データ取得中...
                    </TableCell>
                  </TableRow>
                )}
                {!noData &&
                  txRowHisotryData.map((row, index) => (
                    <Row key={index} row={row} pageNumber={pageNumber} index={index} />
                  ))}
                {!pageTopLoader && noData && (
                  <TableRow>
                    <TableCell align="center" colSpan={6}>
                      表示できるデータがありません
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      {totalPageNum > 0 && (
        <Grid
          container
          spacing={1}
          className={classes.pageSection}
          alignItems="center"
          justifyContent="center"
        >
          <Pagination
            count={totalPageNum}
            page={pageNumber}
            onChange={handlePageChange}
          />
        </Grid>
      )}
    </C.ContentWrapper>
  );
};

PicTreePointExHistory.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  currentPage: state.page.currentPage,
  txHistoryDataFilter: state.nftDepTxHisotry.txHistoryDataFilter,
  pageTopLoader: state.page.pageTopLoader,
  filterDrawerState: state.page.filterDrawerState,
  nftTxCsvLoader: state.nftDepTxHisotry.nftTxCsvLoader,
  itemTxCsvData: state.inGameItemSalesHistory.itemTxCsvData,
  dateRangePicTree: state.page.dateRangePicTree,
  pointExchangeData: state.pictree.pointExchangeData,
  pageNumber: state.pictree.pointExchangePageNumber,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentPage: (param) => dispatch(setCurrentPage(param)),
  setTxHistoryDataFilter: (param) => dispatch(setTxHistoryDataFilter(param)),
  setFilterDrawerState: (param) => dispatch(setFilterDrawerState(param)),
  setPointExchangeDataPicTreeNumber: (param) => dispatch(setPointExchangeDataPicTreeNumber(param)),
  getInGameItemHistoryCsvData: (param) => dispatch(getInGameItemHistoryCsvData(param)),
  setNftTxCsvDownloader: (param) => dispatch(setNftTxCsvDownloader(param)),
  getPointExchangeDataPicTree: (param) => dispatch(getPointExchangeDataPicTree(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(PicTreePointExHistory));
