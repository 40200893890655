import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Breadcrumbs,
  Grid,
  Card,
  CardContent,
  Typography,
  Chip,
} from "@material-ui/core";
import { withStyles, makeStyles, createStyles } from "@material-ui/core/styles";
import HomeStyles from "pages/HomeStyles";
import {
  setCurrentPage,
  getPictreeAdSummary,
  getPictreeAdTrend,
} from "actions";
import * as C from "components";
import * as V from "styles/variables";
import Summary from "components/PicTree/Ad/Summary";
import LineChartPictreeAd from "components/Charts/LineChartPictreeAd";
import PicTreeTableAd from "components/Table/PicTreeTableAd";
import ChartConfig from "utils/ChartConfig";

const useStyles = makeStyles((theme) =>
  createStyles({
    loaderBox: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: 280,
    },
    loaderBarImg: {
      width: 40,
    },
    breadcrumbs: {
      marginTop: 16,
      minHeight: 30,
    },
    gridSection: {
      marginTop: theme.spacing(1),
    },
    selectGameTxt: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: 280,
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
      },
    },
  })
);

const PicTreeAdImp = ({
  setCurrentPage,
  pageTopLoader,
  dateRangePicTree,
  getPictreeAdSummary,
  getPictreeAdTrend,
  adSummaryData,
  adTrendData,
}) => {
  const classes = useStyles();
  const [chartState, setChartState] = useState(true);
  const [initialDataCall, setInitialDataCall] = useState(true);

  useEffect(() => {
    setCurrentPage(window.location.pathname);
  }, []);

  useEffect(() => {
    if (dateRangePicTree[0] !== null && dateRangePicTree[1]!== null
      && initialDataCall) {
      getPictreeAdSummary();
      getPictreeAdTrend();
      setInitialDataCall(false);
    }
  }, [dateRangePicTree]);

  return (
    <Fragment>
      <C.ContentWrapper>
        <Grid
          container
          spacing={0}
          direction="row"
          style={{ marginTop: -16, marginBottom: 8 }}
          justifyContent="space-between"
        >
          <Grid item>
            <Breadcrumbs separator="›" className={classes.breadcrumbs}>
              <Typography>PicTrée TEPCO</Typography>
              <Typography variant="body1">広告</Typography>
              <Typography variant="body1">概要</Typography>
            </Breadcrumbs>
          </Grid>
          <Grid item className={classes.fillRemailSpace}></Grid>
          <Grid item>
            <C.CalendarRangePicker mDate={"2024/05/04"} page="pictree" pictree />
          </Grid>
        </Grid>

        <Grid container spacing={0} style={{ marginTop: 0 }}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <div className={classes.cardContentSection}>
                  <Summary />
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Grid container spacing={1} className={classes.gridSection}>
          <Grid item xs={12} md={12} lg={12}>
            {pageTopLoader ? (
              <div className={classes.loaderBox}>
                <img
                  className={classes.loaderBarImg}
                  src={`/static/images/loader-bar.gif`}
                />
              </div>
            ) : chartState ? (
              adTrendData.length > 0 ? (
                <LineChartPictreeAd
                  legend={ChartConfig.legendPicTreeAdTrend}
                  lblSaveImage={""}
                  xAisData={adTrendData[0]["date"]}
                  seriesData={adTrendData}
                  chartType={"line"}
                />
              ) : (
                <div className={classes.selectGameTxt}>
                  <Chip
                    label="No Data"
                    style={{
                      color: V.textColor,
                      backgroundColor: V.placeholderColor,
                    }}
                  />
                </div>
              )
            ) : null}
          </Grid>
        </Grid>

        <Grid container spacing={1} className={classes.gridSection}>
          <Grid item xs={12} md={12} lg={12}>
            <PicTreeTableAd game="PicTree" />
          </Grid>
        </Grid>
      </C.ContentWrapper>
    </Fragment>
  );
};

PicTreeAdImp.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  pageTopLoader: state.page.pageTopLoader,
  dateRangePicTree: state.page.dateRangePicTree,
  adSummaryData: state.pictree.adSummary,
  adTrendData: state.pictree.adTrend,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentPage: (param) => dispatch(setCurrentPage(param)),
  getPictreeAdSummary: () => dispatch(getPictreeAdSummary()),
  getPictreeAdTrend: () => dispatch(getPictreeAdTrend()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(HomeStyles, { withTheme: true })(PicTreeAdImp));
