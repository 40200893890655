import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from "moment";
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { GetApp } from '@material-ui/icons';
import { CSVLink } from "react-csv";
import * as C from 'components';

const useStyles = makeStyles({
  loaderBox: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loaderBarImg: {
    width: 40,
  },
  tableContainer: {
    display: 'flex',
    minHeight: 200,
  },
  dateTableWrapper: {
    float: 'left',
  },
  dataTableWrapper: {
    float: 'left',
    overflow: 'scroll',
  },
  leftTable: {
    border: '1px solid #eee',
  },
  lblGameName: {
    color: '#000',
    fontSize: 13,
    minWidth: 160,
  },
  lblUniqueUser: {
    color: '#6f6969',
    fontSize: 11,
    minWidth: 180,
  },
  _GAIv: {
    height: 40,
    backgroundColor: "#4#4#4",
    borderLeft: "1px solid #eee6e6",
    color: "#666",
    fontSize: 11,
    minWidth: 80,
    paddingRight: 10,
    textAlign: 'right',
  },
  dataHeaderTd: {
    width: 100,
    paddingRight: 10,
    borderBottom: '1px solid #eee6e6',
  },
  dataTableBody: {
    fontSize: 11,
    paddingRight: 10,
    paddingBottom: 0,
    textAlign: 'right',
    verticalAlign: 'middle',
    border: '1px solid #eee6e6',
    height: '3em',
    overflowX: 'auto',
  },
  dataCellTd: {
    fontSize: 13,
    paddingRight: 10,
    paddingBottom: 0,
    paddingLeft: 10,
    textAlign: 'right',
    verticalAlign: 'middle',
    borderBottom: '1px solid #eee6e6',
    borderRight: '1px solid #eee6e6',
    height: 45,
  },
  tTdHeader: {
    paddingLeft: '1rem',
    paddingRight: 19,
    fontWeight: 'bold',
    height: 39,
  },
  tTd: {
    height: 44,
    width: 250,
    paddingLeft: '1rem',
    borderBottom: '1px solid #eee',
    borderCollapse: 'collapse',
  },
  tableNumberData: {
    fontSize: 13,
    paddingBottom: 4,
  },
  exportBtn: {
    marginLeft: 16,
  },
  titleContainer: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  widgetTitle: {
    flexBasis: '50%',
    fontSize: 16,
    padding: 16,
  },
  exportBtnContainer: {
    marginLeft: 'auto',
    padding: 16,
  }
});

const PicTreeTableAd = ({
  adTrendData,
  chartSize,
  pageTopLoader,
}) => {
  const classes = useStyles();
  const getTableLabelColumn = () => {
    return  <>
              <tr>
                <td className={classes.tTd}>
                  <div className={classes.lblGameName}>インプレッション</div>
                </td>
              </tr>
              <tr>
                <td className={classes.tTd}>
                  <div className={classes.lblGameName}>クリック</div>
                </td>
              </tr>
              <tr>
                <td className={classes.tTd}>
                  <div className={classes.lblGameName}>インプレッション・ユニーク</div>
                </td>
              </tr>
              <tr>
                <td className={classes.tTd}>
                  <div className={classes.lblGameName}>クリック・ユニーク</div>
                </td>
              </tr>
            </>
  }

  const getDataTable = (adTrendData) => {
    return  <>
              <tr>
              {adTrendData[0]["impression"].map((d, i) =>
                <td className={classes.dataCellTd} key={i}>{d}</td>
              )}
              </tr >
              <tr>
              {adTrendData[0]["click"].map((d, i) =>
                <td className={classes.dataCellTd} key={i}>{d}</td>
              )}
              </tr >
              <tr>
              {adTrendData[0]["impressionUni"].map((d, i) =>
                <td className={classes.dataCellTd} key={i}>{d}</td>
              )}
              </tr >
              <tr>
              {adTrendData[0]["clickUni"].map((d, i) =>
                <td className={classes.dataCellTd} key={i}>{d}</td>
              )}
              </tr >
            </>
  }

  const getExportData = (adTrendData) => {
    let exportData = [];
    if (adTrendData.length > 0) {
      let headerArray = ["日付"];
      let numOfHeaderData = adTrendData[0]['date'].length;
      for (let j=0; j<numOfHeaderData; j++) {
        headerArray.push(
          chartSize !== "hour"
            ? moment(adTrendData[0]['date'][j]).format('M/D')
            : moment(adTrendData[0]['date'][j]).format('M/D  HH:mm')
        );
      }
      exportData.push(headerArray);

      let countArray = ["インプレッション"];
      for (let j=0; j<numOfHeaderData; j++) {
        countArray.push(adTrendData[0]['impression'][j]);
      }
      exportData.push(countArray);

      let upuArray = ["クリック"];
      for (let j=0; j<numOfHeaderData; j++) {
        upuArray.push(adTrendData[0]['click'][j]);
      }
      let impUniArray = ["インプレッション・ユニーク"];
      for (let j=0; j<numOfHeaderData; j++) {
        impUniArray.push(adTrendData[0]['impressionUni'][j]);
      }
      exportData.push(impUniArray);

      let clickUniArray = ["クリック・ユニーク"];
      for (let j=0; j<numOfHeaderData; j++) {
        clickUniArray.push(adTrendData[0]['clickUni'][j]);
      }
      exportData.push(clickUniArray);
    }
    return exportData;
  }

  return (
    <Paper>
      <div className={classes.titleContainer}>
        <div className={classes.widgetTitle}></div>
        <div className={classes.exportBtnContainer}>
          <CSVLink
              data={getExportData(adTrendData)}
              filename={`PicTree - バナー広告データ`}
          >
            <Button
                variant="contained"
                color="default"
                className={classes.exportBtn}
                startIcon={<GetApp />}
            >
              CSV出力
            </Button>
          </CSVLink>
        </div>
      </div>
      <div className={classes.tableContainer}>
      {pageTopLoader
        ? <div className={classes.loaderBox}><img className={classes.loaderBarImg} src={`/static/images/loader-bar.gif`} /></div>
        : adTrendData.length > 0 ?
        adTrendData.length > 0 ?
              <>
                <div className={classes.dateTableWrapper}>
                  <table className={classes.leftTable}>
                      <tbody>
                      <tr>
                          <td className={classes.tTdHeader}>
                          </td>
                      </tr >
                      {getTableLabelColumn(adTrendData)}
                      </tbody>
                  </table>
                </div>

                <div className={classes.dataTableWrapper}>
                  <table className={classes.dataTableBody}>
                    <tbody>
                      <tr className={classes._GAIv}>
                      {adTrendData[0]['date'].map((item, i) => (
                        <td className={classes.dataHeaderTd} key={i}>
                          {chartSize !== "hour"
                            ? moment(item).format('M/D')
                            : moment(item).format('M/D HH:mm')}
                        </td>
                      ))}
                      </tr >
                        {getDataTable(adTrendData)}
                    </tbody>
                  </table>
                </div>
              </>
              : <div className={classes.loaderBox}><C.NoData text="データがありません" /></div>
            : null
        }
      </div>
    </Paper>
  )
}

PicTreeTableAd.propTypes = {
    classes: PropTypes.object,
};

const mapStateToProps = state => ({
  adTrendData: state.pictree.adTrend,
  chartSize: state.pictree.chartSize,
  pageTopLoader: state.page.pageTopLoader,
});

const mapDispatchToProps = dispatch => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(PicTreeTableAd);
