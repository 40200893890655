import { put, call, takeEvery, select, all } from "redux-saga/effects";
import _ from 'lodash';
import moment from 'moment-timezone';
import { getJwtToken, getDataFromRDS, getDataFromSxi, getDataFromKpiDash } from "api";
import { PICTREE, ENDPOINT } from "../../constants";

import {
  setPageTopLoader,
  setSeasonData,
  setSeasonDataNtt,
} from 'actions';

export function* handleSeasonData() {
  try {
    const jwtToken = yield call(getJwtToken);
    const sqlQueryGetSeason = {
      query: `
        SELECT gs.id           AS 'seasonId'
            ,sn.official_name  AS 'seasonName'
            ,gt.start_datetime AS 'seasonStart'
            ,gt.end_datetime   AS 'seasonEnd'
        FROM game_space gs
        INNER JOIN game_term gt ON gt.id = gs.game_term_id
        INNER JOIN season_name sn ON sn.season_id = gs.id AND sn.language_code = 'JA'
        ORDER BY gt.start_datetime DESC
      `,
      database: "KpiDashboard",
      type: "list",
    };
    const [dataSeasonDataTemp] = yield all([
        call(getDataFromSxi, sqlQueryGetSeason, ENDPOINT.GET_DATA_SXI_DENCHU, jwtToken),
      ]);

    let seasons = [], seasonObj = {};
    _.each(dataSeasonDataTemp, function(s) {
      if (s.seasonId !== "clxvw56es00005soo28mj4jfs" &&
        s.seasonId !== "cm46zidp10001bpp63r3cfhwx" && // akita 1
        s.seasonId !== "cm64mqm94000br3n0esu8c0n4" && // kitakanto
        s.seasonId !== "cm46zidp30003bpp6cxq101mo" && // akita 2
        // s.seasonId !== "cm64mqm920003r3n0h0inau4q" && // numazu2
        // s.seasonId !== "cm64mqm920005r3n03fh92tre" && // numazu3
        // s.seasonId !== "cm64mqm920007r3n062h28osr" && // numazu4
        s.seasonId !== "cm64mqm930009r3n01z5xbr5d" // numazu5
      ) {
        seasonObj.seasonId = s.seasonId;
        seasonObj.seasonName = s.seasonName;
        seasonObj.seasonStart = moment(s.seasonStart).tz("Asia/Tokyo").format("YYYY-MM-DD HH:mm");
        seasonObj.seasonEnd = moment(s.seasonEnd).tz("Asia/Tokyo").format("YYYY-MM-DD HH:mm");
        seasons.push(seasonObj);
        seasonObj = {};
      }
    });
    yield put(setSeasonData(seasons));
  } catch (error) {
    console.log(error);
  }
}

export function* handleSeasonDataNtt() {
  try {
    const jwtToken = yield call(getJwtToken);
    const sqlQueryGetSeason = {
      query: `
        SELECT gs.id           AS 'seasonId'
            ,sn.official_name  AS 'seasonName'
            ,gt.start_datetime AS 'seasonStart'
            ,gt.end_datetime   AS 'seasonEnd'
        FROM game_space gs
        INNER JOIN game_term gt ON gt.id = gs.game_term_id
        INNER JOIN season s ON gs.game_term_id = s.id
        INNER JOIN season_name sn ON sn.season_id = s.id AND sn.language_code = 'JA'
        WHERE gs.id IN ('cm46zidp10001bpp63r3cfhwx', 'cm46zidp30003bpp6cxq101mo', 'cm64mqm94000br3n0esu8c0n4')
        ORDER BY gt.start_datetime DESC
      `,
      database: "KpiDashboard",
      type: "list",
    };
    const [dataSeasonDataTemp] = yield all([
      call(getDataFromSxi, sqlQueryGetSeason, ENDPOINT.GET_DATA_SXI_DENCHU, jwtToken),
    ]);

    const seasonDataCorrection = [
      {
        "seasonId": "cm64mqm94000br3n0esu8c0n4",
        "seasonName": "北関東シーズン",
        "seasonStart": "2025-02-01T22:00:00.000Z",
        "seasonEnd": "2025-03-16T07:00:00.000Z"
      },
      {
        "seasonId": "cm46zidp30003bpp6cxq101mo",
        "seasonName": "秋田県北 & 県央・県南シーズン",
        "seasonStart": "2024-12-06T23:00:00.000Z",
        "seasonEnd": "2025-01-12T07:00:00.000Z"
      },
    ];

    let seasons = [], seasonObj = {};
    _.each(seasonDataCorrection, function(s) {
      seasonObj.seasonId = s.seasonId;
      seasonObj.seasonName = s.seasonName;
      seasonObj.seasonStart = moment(s.seasonStart).tz("Asia/Tokyo").format("YYYY-MM-DD HH:mm");
      seasonObj.seasonEnd = moment(s.seasonEnd).tz("Asia/Tokyo").format("YYYY-MM-DD HH:mm");
      seasons.push(seasonObj);
      seasonObj = {};
    });
    yield put(setSeasonDataNtt(seasons));
  } catch (error) {
    console.log(error);
  }
}

export default function* watchMapSettings() {
  yield takeEvery(PICTREE.GET_SEASON_DATA, handleSeasonData);
  yield takeEvery(PICTREE.GET_SEASON_DATA_NTT, handleSeasonDataNtt);
}
