
import { put, call, takeEvery, select, all } from "redux-saga/effects";
import _ from 'lodash';
import moment from 'moment-timezone';
import { getDataFromKpiDash } from "api";
import { PICTREE, ENDPOINT } from "../../constants";

import {
  setPageTopLoader,
  setMissionData,
  setMissionSummaryData,
  setMissionRankingData,
} from 'actions';

const isStarted = (startTime, currentTime) => {
  return moment(startTime).isBefore(currentTime);
};

const isHappening = (startTime, endTime, currentTime) => {
  const start = moment(startTime);
  const end = moment(endTime);
  const current = moment(currentTime);

  // Check if current time falls within the start and end time
  return current.isBetween(start, end, null, '[)');
};

const getBetweenMin = (startTime, firstTime) => {
  const occurredTime = moment(startTime);
  const achievedTime = moment(firstTime);
  return achievedTime.diff(occurredTime, 'minutes')
};

export const dateRange = (state) => state.page.dateRangePicTreeMission;
export const startDate = state => state.page.dateRangePicTree[0];
export const endDate = state => state.page.dateRangePicTree[1];

export function* handleMissionData() {
  try {
    yield put(setPageTopLoader(true));
    const rangeTarget = yield select(dateRange);
    const sqlQueryMissionAchieveCount = {
      query: `
        SELECT gs.id, gt.start_datetime as 'missionStart'
        , gt.end_datetime as 'missionEnd'
        , mn.name AS 'missionName'
        , COUNT(me.id) AS 'achievedNum'
        , CONVERT_TZ(gt.start_datetime, '+00:00', '+09:00') AS occurred_datetime_jst
        , CONVERT_TZ(gt.end_datetime, '+00:00', '+09:00') AS end_datetime_jst
        , CONVERT_TZ(MIN(me.occurred_datetime), '+00:00', '+09:00') AS achieved_datetime_first
        FROM game_space gs
        LEFT JOIN mission_event me ON gs.id = me.game_space_id AND me.type = 'MISSION_ACHIEVED'
        JOIN mission_name mn ON gs.id = mn.mission_id AND mn.language_code = 'JA'
        JOIN game_term gt ON gt.id = gs.game_term_id
        WHERE gt.start_datetime < now() AND gs.id != 'cm0zde85z000407506byxr3bu'
        AND CONVERT_TZ(gt.start_datetime, 'UTC', 'Asia/Tokyo') BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
        AND gs.id != 'cm1p2fudc000o07501pjyy0ki'
        GROUP BY gs.id, gt.start_datetime, gt.start_datetime, mn.name
        ORDER BY missionStart DESC;
      `,
    };
    const sqlQueryCountAchieversUnique = {
      query: `
        SELECT ps.name, me.type, COUNT(*) as achievedNum
        FROM mission_event me
        JOIN game_space gs ON gs.id = me.game_space_id
        JOIN player_state ps ON me.player_id = ps.player_id
        JOIN mission_name mn ON gs.id = mn.mission_id
        WHERE me.type = 'MISSION_ACHIEVED' AND me.game_space_id != 'cm0zde85z000407506byxr3bu'
        AND CONVERT_TZ(me.occurred_datetime, 'UTC', 'Asia/Tokyo') BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
        AND me.game_space_id != 'cm1p2fudc000o07501pjyy0ki'
        GROUP BY ps.player_id, ps.name
        ORDER BY achievedNum DESC;
      `
    };
    const sqlQureyAssetCount = {
      query: `
        SELECT
            pae.game_space_id AS game_space_id, mn.mission_id AS mission_id, mn.name AS mission_name,
            COUNT(DISTINCT mn.mission_id) AS mission_count,
            COUNT(DISTINCT ps.player_id) AS player_count,
            COUNT(DISTINCT ppa.file_name) AS pic_count,
            COUNT(DISTINCT pae.power_asset_id) AS asset_count,
            CONVERT_TZ(pae.occurred_datetime, '+00:00', '+09:00') AS occurred_datetime_jst,
            CONVERT_TZ(gt.end_datetime, '+00:00', '+09:00') AS end_datetime_jst
        FROM
            power_asset_event pae
        JOIN
            photo_of_power_asset ppa ON ppa.power_asset_id = pae.power_asset_id
            AND ppa.player_id = pae.player_id
            AND ppa.game_space_id = pae.game_space_id
        JOIN
            game_space gs ON gs.id = pae.game_space_id
        JOIN mission_name mn ON gs.id = mn.mission_id
        JOIN
            game_term gt ON gt.id = gs.game_term_id
        JOIN
            player_state ps ON pae.player_id = ps.player_id
        JOIN
            review_request_photo_bind rrpb ON rrpb.photo_of_power_asset_id = ppa.id
        JOIN
            power_asset pa ON pa.id = pae.power_asset_id
        WHERE
            pae.type = 'PHOTOGRAPH'
            AND gs.type = 'MISSION'
            AND pa.type = 'MISSION_ASSET'
            AND mn.language_code = 'JA'
            AND pae.game_space_id != 'cm0zde85z000407506byxr3bu'
            AND pae.game_space_id != 'cm1p2fudc000o07501pjyy0ki'
            AND CONVERT_TZ(pae.occurred_datetime, 'UTC', 'Asia/Tokyo') BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
        GROUP BY pae.game_space_id
        ORDER BY
            pae.occurred_datetime ASC
      `
    };

    const sqlQureyPlayerCount = {
      query: `
        SELECT
            ps.name,
            COUNT(DISTINCT ppa.file_name) AS pic_count,
            COUNT(DISTINCT pae.power_asset_id) AS asset_count
        FROM
            power_asset_event pae
        JOIN
            photo_of_power_asset ppa ON ppa.power_asset_id = pae.power_asset_id
            AND ppa.player_id = pae.player_id
            AND ppa.game_space_id = pae.game_space_id
        JOIN
            game_space gs ON gs.id = pae.game_space_id
        JOIN
            game_term gt ON gt.id = gs.game_term_id
        JOIN
            player_state ps ON pae.player_id = ps.player_id
        JOIN
            review_request_photo_bind rrpb ON rrpb.photo_of_power_asset_id = ppa.id
        JOIN
            power_asset pa ON pa.id = pae.power_asset_id
        JOIN mission_name mn ON gs.id = mn.mission_id
        WHERE
            pae.type = 'PHOTOGRAPH'
            AND gs.type = 'MISSION'
            AND pa.type = 'MISSION_ASSET'
            AND pae.game_space_id != 'cm0zde85z000407506byxr3bu'
            AND pae.game_space_id != 'cm1p2fudc000o07501pjyy0ki'
            AND CONVERT_TZ(pae.occurred_datetime, 'UTC', 'Asia/Tokyo') BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
        GROUP BY ps.player_id
        ORDER BY pic_count DESC
      `
    };
    // console.log("LOG:: sqlQueryMissionAchieveCount:", sqlQueryMissionAchieveCount);
    // console.log("LOG:: sqlQureyAssetCount:", sqlQureyAssetCount);

    const [dataMissionAchieveCountTemp, dataMissionAchieverTemp,
      dataAssetCountTemp, dataPlayerCountTemp] =
    yield all([
      call(getDataFromKpiDash, sqlQueryMissionAchieveCount, ENDPOINT.GET_DATA_API),
      call(getDataFromKpiDash, sqlQueryCountAchieversUnique, ENDPOINT.GET_DATA_API),
      call(getDataFromKpiDash, sqlQureyAssetCount, ENDPOINT.GET_DATA_API),
      call(getDataFromKpiDash, sqlQureyPlayerCount, ENDPOINT.GET_DATA_API),
    ]);
    // console.log("LOG dataMissionAchieveCountTemp:", JSON.parse(dataMissionAchieveCountTemp.body));
    // console.log("LOG dataMissionAchieverTemp:", JSON.parse(dataMissionAchieverTemp.body));
    // console.log("LOG dataAssetCountTemp:", JSON.parse(dataAssetCountTemp.body));
    // console.log("LOG dataPlayerCountTemp:", JSON.parse(dataPlayerCountTemp.body));

    const missionData = JSON.parse(dataMissionAchieveCountTemp.body);
    const totalAchievedCount = _.sumBy(missionData, 'achievedNum');
    const countAchievedZero = _.size(_.filter(missionData, { achievedNum: 0 }));
    const countAchieved = missionData.length - countAchievedZero;

    const achieverData = JSON.parse(dataMissionAchieverTemp.body);
    const assetCountData = JSON.parse(dataAssetCountTemp.body);
    const playerCountData = JSON.parse(dataPlayerCountTemp.body);
    // const dataCountTotalEntry = JSON.parse(dataCountTotalEntryTemp.body);
    // console.log("LOG achieveCountByMissionData:", achieveCountByMissionData);
    // console.log("LOG playerCountData:", playerCountData);
    // console.log("LOG dataCountTotalEntry:", dataCountTotalEntry);

    let missions = [], missionObj = {}, missionsStarted = [];
    const currentTime = moment().tz("Asia/Tokyo").format("YYYY-MM-DD HH:mm");
    _.each(missionData, function(m) {
      missionObj.missionId = m.missionId;
      missionObj.missionName = m.missionName;
      missionObj.missionStart = moment(m.missionStart).tz("Asia/Tokyo").format("YYYY-MM-DD HH:mm");
      missionObj.missionEnd = moment(m.missionEnd).tz("Asia/Tokyo").format("YYYY-MM-DD HH:mm");
      missions.push(missionObj);
      if (isStarted(moment(m.missionStart).tz("Asia/Tokyo").format("YYYY-MM-DD HH:mm"), currentTime)) {
        missionsStarted.push(missionObj);
      }
      missionObj = {};
    });

    const totalMissionCount = _.sumBy(assetCountData, 'mission_count');
    const totalPicCount = _.sumBy(assetCountData, 'pic_count');
    const totalAssetCount = _.sumBy(assetCountData, 'asset_count');
    const totalPlayerCount = playerCountData.length;
    const totalAttendCount = _.sumBy(assetCountData, 'player_count');

    // mission table data
    let missionTableData = [], firstTimeMinArray = [];
    _.each(missionData, function(d) {
      const achiever = _.find(assetCountData, { game_space_id: d.id });
      missionObj.missionId = d.id;
      missionObj.occurredDatetimeJst = d.occurred_datetime_jst.slice(0, 16).replace('T', ' ');
      missionObj.endDatetimeJst = d.end_datetime_jst.slice(0, 16).replace('T', ' ');
      missionObj.firstAchieveMin = d.achieved_datetime_first !== null
      ? getBetweenMin(d.occurred_datetime_jst, d.achieved_datetime_first)
      : "-"
      missionObj.name = d.missionName;
      missionObj.achieverCount = d.achievedNum;
      missionObj.playerCount = achiever !== undefined ? achiever.player_count : 0; //d.player_count ? d.player_count : 0;
      missionObj.picCount = achiever !== undefined ? achiever.pic_count : 0;
      missionObj.assetCount = achiever !== undefined ? achiever.asset_count : 0;
      missionObj.happening = isHappening(missionObj.occurredDatetimeJst, missionObj.endDatetimeJst, currentTime)
      missionTableData.push(missionObj);
      if (missionObj.firstAchieveMin !== "-") {
        firstTimeMinArray.push(missionObj.firstAchieveMin);
      }
      missionObj = {};
    });
    const allMinSpent = _.sum(firstTimeMinArray);

    // Mui-DataTable
    let missionTableDataMui = [];
    _.each(missionData, function(d, index) {
      const achiever = _.find(assetCountData, { game_space_id: d.id });
      const startTime = d.occurred_datetime_jst.slice(0, 16).replace('T', ' ');
      const endTime = d.end_datetime_jst.slice(0, 16).replace('T', ' ');
      const missionNumbering = missionData.length - index;
      missionTableDataMui.push([
        missionNumbering,
        d.missionName,
        isHappening(missionTableData[index]["occurredDatetimeJst"], missionTableData[index]["endDatetimeJst"], currentTime),
        `${startTime}~${moment(endTime).format("HH:mm")}`,
        `${moment(endTime).diff(moment(startTime), "hours")}h`,
        d.achieved_datetime_first !== null
          ? getBetweenMin(d.occurred_datetime_jst, d.achieved_datetime_first) : 0,
        d.achievedNum,
        achiever !== undefined ? achiever.player_count : 0,
        achiever !== undefined ? achiever.pic_count : 0,
        achiever !== undefined ? achiever.asset_count : 0,
      ]);
    });

    const missionSummaryData = {
      // registNumber: missionData.length,
      startedNumber: missionsStarted.length,
      countAchieved: countAchieved,
      countAchievers: achieverData.length,
      totalAchievedCount: totalAchievedCount,
      totalAchievedAndNonAchievedCount: totalAttendCount,
      totalMissionCount: totalMissionCount,
      totalPlayerCount: totalPlayerCount,
      totalPicCount: totalPicCount,
      totalAssetCount: totalAssetCount,
      missionTableData: missionTableData,
      missionTableDataMui: missionTableDataMui,
      avgMinutesTillFirstAchieve: (allMinSpent / firstTimeMinArray.length).toFixed(0),
    }

    yield put(setMissionData(missions));
    yield put(setMissionSummaryData(missionSummaryData));
    yield put(setPageTopLoader(false));
  } catch (error) {
    console.log(error);
  }
}

export function* handleMissionRankingData() {
  try {
    const rangeTarget = yield select(dateRange);
    const sqlQueryMissionRanking = {
      query: `
        SELECT ps.player_id, ps.name, COUNT(*) as achievedNum
        FROM mission_event me
        JOIN game_space gs ON gs.id = me.game_space_id
        JOIN player_state ps ON me.player_id = ps.player_id
        WHERE me.type = 'MISSION_ACHIEVED' AND me.game_space_id != 'cm0zde85z000407506byxr3bu'
        AND me.game_space_id != 'cm1p2fudc000o07501pjyy0ki'
        AND CONVERT_TZ(me.occurred_datetime, 'UTC', 'Asia/Tokyo') BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
        GROUP BY ps.player_id, ps.name
        ORDER BY achievedNum DESC;
      `
    }

    const [dataRankingTemp] =
    yield all([
      call(getDataFromKpiDash, sqlQueryMissionRanking, ENDPOINT.GET_DATA_API),
    ]);
    const rankingData = JSON.parse(dataRankingTemp.body);
    let rankingTableData = [];
    _.each(rankingData, function(d, index) {
      rankingTableData.push([
        index+1,
        d.player_id,
        d.name,
        d.achievedNum
      ]);
    })
    yield put(setMissionRankingData(rankingTableData));
  } catch (error) {
    console.log(error);
  }
}
export default function* watchMapSettings() {
  yield takeEvery(PICTREE.GET_MISSION_DATA, handleMissionData);
  yield takeEvery(PICTREE.GET_MISSION_RANKING_DATA, handleMissionRankingData);
}