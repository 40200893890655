const FormatNumStrHelper = {
  formatCommaThousand: function (value, decimal = 0) {
    if (value === "-") {
      return value;
    }
    if (typeof value === "string") {
      return value;
    }
    let returnVal = Number(value).toFixed(decimal);
    if (returnVal === 0) {
      return returnVal;
    }
    if (returnVal)
      return returnVal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  formatAffiliType: function (type) {
    let typeLbl = "";
    if (type === "individual") {
      typeLbl = "個人";
    } else if (type === "media") {
      typeLbl = "メディア";
    } else if (type === "organization") {
      typeLbl = "企業";
    } else if (type === "campaign") {
      typeLbl = "LP経由";
    } else if (type === "campaign_game") {
      typeLbl = "直アクセス";
    }
    return typeLbl;
  },
  formatCampaignType: function (type) {
    if (type === "campaign") {
      return "LP経由";
    } else if (type === "campaign_game") {
      return "直アクセス";
    }
  },
  capitalize: function (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  unEscapeUnicode: function (str) {
    if (str === undefined) {
      return;
    }
    return str.replace(/\\u([a-fA-F0-9]{4})/g, function (m0, m1) {
      return String.fromCharCode(parseInt(m1, 16));
    });
  },
  formatGameKeyToName: function (game) {
    let returnValue = "";
    switch (game) {
      case "nftdep":
        returnValue = "PlayMining NFT";
        break;
      case "jobtribes":
        returnValue = "JobTribes";
        break;
      case "puzzle":
        returnValue = "Puzzle";
        break;
      case "luckyfarmer":
        returnValue = "LuckyFarmer";
        break;
      case "cookinburger":
        returnValue = "CookinBurger";
        break;
      case "dragonramen":
        returnValue = "DragonRamen";
        break;
      case "graffiti":
        returnValue = "GraffitiRacer";
        break;
      default:
    }
    return returnValue;
  },
  formatTransactionTypeToName: function (type) {
    let returnValue = "";
    switch (type) {
      case "store-count":
        returnValue = "1次販売数";
        break;
      case "auction-count":
        returnValue = "2次販売数";
        break;
      case "activeWallet":
        returnValue = "ユニークWallet";
        break;
      case "store-amount":
        returnValue = "1次販売額";
        break;
      case "auction-amount":
        returnValue = "2次販売額";
        break;
      case "allAmulet":
        returnValue = "全アミュレット";
        break;
      default:
    }
    return returnValue;
  },
  formatKandM: function (num) {
    if (num > 999 && num < 1000000) {
      return (num / 1000).toFixed(0) + "K"; // convert to K for number from > 1000 < 1 million
    } else if (num >= 1000000) {
      return (num / 1000000).toFixed(0) + "M"; // convert to M for number from > 1 million
    } else if (num < 900) {
      return num; // if value < 1000, nothing to do
    }
  },
};

export default FormatNumStrHelper;
