import React, { useState, useEffect } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import {

} from "actions";
import StatBoxSlim from "components/Stats/StatBoxSlim";

const useStyles = makeStyles((theme) => ({
  titlePaper: {
    paddingTop: 8,
    paddingRight: 8,
    marginBottom: 8,
    marginLeft: 8,
    fontSize: 18,
  },
  statsBox: {
    padding: 8,
  },
  bottomBoxArea: {
    fontSize: 14,
    marginTop: 16,
  },
}));

const PointStats = ({
  loading,
  pointOverview,
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
      style={{ marginTop: -8, marginBottom: 8, width: "100%" }}
    >
      <Paper style={{ padding: 8, marginBottom: 8, width: "98.6%" }}>
        <Grid
          container
          spacing={0}
          direction="row"
          style={{ marginTop: -16, marginBottom: 8, width: "100%" }}
        >
          <Grid item xs={12} sm={6} md={6} lg={2} className={classes.statsBox}>
            <StatBoxSlim
              title="報酬支払い総額"
              value={
                loading
                  ? "loading"
                  : pointOverview.length > 0 ? pointOverview[0]["rewardPoint"] : "--"
              }
              prevValue={
                loading
                  ? "loading"
                  : pointOverview.length > 0 ? pointOverview[0]["rewardPointPrev"] : "--"
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={2} className={classes.statsBox}>
            <StatBoxSlim
              title="交換ポイント総額"
              value={
                loading
                  ? "loading"
                  : pointOverview.length > 0 ? pointOverview[0]["totalPoint"] : "--"
              }
              prevValue={
                loading
                  ? "loading"
                  : pointOverview.length > 0 ? pointOverview[0]["totalPointPrev"] : "--"
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={2} className={classes.statsBox}>
            <StatBoxSlim
              title="報酬支払い件数"
              value={loading ? "loading" : pointOverview.length > 0 ? pointOverview[0]["rewardPointTx"] : "--"}
              prevValue={loading ? "loading" : pointOverview.length > 0 ? pointOverview[0]["rewardPointTxPrev"] : "--"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={2} className={classes.statsBox}>
            <StatBoxSlim
              title="交換取引件数"
              value={loading ? "loading" : pointOverview.length > 0 ? pointOverview[0]["countTx"] : "--"}
              prevValue={loading ? "loading" : pointOverview.length > 0 ? pointOverview[0]["countTxPrev"] : "--"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={2} className={classes.statsBox}>
            <StatBoxSlim
              title="DEP交換"
              value={loading ? "loading" : pointOverview.length > 0 ? pointOverview[0]["dep"] : "--"}
              prevValue={loading ? "loading" : pointOverview.length > 0 ? pointOverview[0]["depPrev"] : "--"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={2} className={classes.statsBox}>
            <StatBoxSlim
              title="Amazonギフト交換"
              value={loading ? "loading" : pointOverview.length > 0 ? pointOverview[0]["amazon"] : "--"}
              prevValue={loading ? "loading" : pointOverview.length > 0 ? pointOverview[0]["amazonPrev"] : "--"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={2} className={classes.statsBox}>
            <StatBoxSlim
              title="グッズ交換"
              value={loading ? "loading" : pointOverview.length > 0 ? pointOverview[0]["goods"] : "--"}
              prevValue={loading ? "loading" : pointOverview.length > 0 ? pointOverview[0]["goodsPrev"] : "--"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={2} className={classes.statsBox}>
            <StatBoxSlim
              title="報酬支払いユニーク数"
              value={loading ? "loading" : pointOverview.length > 0 ? pointOverview[0]["rewardPointUnique"] : "--"}
              prevValue={loading ? "loading" : pointOverview.length > 0 ? pointOverview[0]["rewardPointUniquePrev"] : "--"}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={2} className={classes.statsBox}>
            <StatBoxSlim
              title="ユニーク交換者数"
              value={loading ? "loading" : pointOverview.length > 0 ? pointOverview[0]["countUnique"] : "--"}
              prevValue={loading ? "loading" : pointOverview.length > 0 ? pointOverview[0]["countUniquePrev"] : "--"}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6} md={6} lg={2} className={classes.statsBox}>
            <StatBoxSlim
              title="初交換者数"
              value={loading ? "loading" : pointOverview.length > 0 ? pointOverview[0]["countFirst"] : "--"}
              prevValue={loading ? "loading" : pointOverview.length > 0 ? pointOverview[0]["countFirstPrev"] : "--"}
              lastItem
            />
          </Grid> */}
        </Grid>
      </Paper>
    </Grid>
  );
};

PointStats.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  pointOverview: state.pictree.pointOverview,
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(null, { withTheme: true })(PointStats));
