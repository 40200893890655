import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Breadcrumbs,
  Grid,
  Typography,
  Chip,
} from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import OverviewStyles from "pages/NftDep/Auction/OverviewStyles";
import {
  setCurrentPage,
  getPointOverviewDataPicTree,
  getPointTrendDataPicTree,
  setPictreeView,
} from "actions";
import * as C from "components";
import * as V from "styles/variables";
import ChartConfig from "utils/ChartConfig";
import PointStats from "components/PicTree/Kakin/PointStats"
import LineChartPictreePoint from "components/Charts/LineChartPictreePoint";
import PicTreeTable from "components/Table/PicTreeTable";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    position: "-webkit-sticky",
    position: "sticky",
    top: 0,
    zIndex: 1000,
  },
  titleTodaysSales: {
    paddingTop: 8,
    paddingBottom: 16,
    fontSize: 12,
  },
  fillRemailSpace: {
    flex: "auto",
  },
  sectionTop: {
    marginTop: -16,
    marginBottom: 8,
    [theme.breakpoints.down("sm")]: {
      marginTop: 8,
    },
  },
  breadcrumbs: {
    marginTop: 16,
    minHeight: 30,
  },
  loaderBox: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 280,
  },
  loaderBarImg: {
    width: 40,
  },
  dataCellTd: {
    fontSize: 13,
    paddingRight: 10,
    paddingBottom: 0,
    paddingLeft: 10,
    textAlign: "right",
    verticalAlign: "middle",
    borderBottom: "1px solid #eee6e6",
    borderRight: "1px solid #eee6e6",
    height: 45,
  },
  lblGameName: {
    color: "#000",
    fontSize: 13,
    minWidth: 160,
  },
  tTd: {
    height: 44,
    width: 250,
    paddingLeft: "1rem",
    borderBottom: "1px solid #eee",
    borderCollapse: "collapse",
  },
  selectGameTxt: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: 280,
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
}));

const PicTreePointExchange = ({
  setCurrentPage,
  getPointOverviewDataPicTree,
  getPointTrendDataPicTree,
  setPictreeView,
  pageTopLoader,
  pictreeView,
  pointTrend,
  dateRangePicTree,
}) => {
  const classes = useStyles();
  const [chartState, setChartState] = useState(true);
  const [initialDataCall, setInitialDataCall] = useState(true);

  useEffect(() => {
    setCurrentPage(window.location.pathname);
    setPictreeView("sales/point/chart");
  }, []);

  useEffect(() => {
    if (dateRangePicTree[0] !== null && dateRangePicTree[1]!== null
      && initialDataCall) {
      getPointOverviewDataPicTree();
      getPointTrendDataPicTree();
      setInitialDataCall(false);
    }
  }, [dateRangePicTree]);

  useEffect(() => {
    if (pictreeView === "sales/point/chart") {
      getPointTrendDataPicTree();
    }
  }, [pictreeView]);

  const getExportData = (data) => {
    let exportData = [];
    if (data.length > 0) {
      let headerArray = ["ポイント交換日"];
      let numOfHeaderData = data[0]["date"].length;
      for (let j = 0; j < numOfHeaderData; j++) {
        headerArray.push(data[0]["date"][j]);
      }
      exportData.push(headerArray);

      let depAmountArray = ["DEP交換額"];
      for (let j = 0; j < numOfHeaderData; j++) {
        depAmountArray.push(data[0]["depPoint"][j]);
      }
      exportData.push(depAmountArray);

      let depCountArray = ["DEP交換件数"];
      for (let j = 0; j < numOfHeaderData; j++) {
        depCountArray.push(data[0]["depCount"][j]);
      }
      exportData.push(depCountArray);

      let amazonAmountArray = ["Amazonギフト交換額"];
      for (let j = 0; j < numOfHeaderData; j++) {
        amazonAmountArray.push(data[0]["amazonPoint"][j]);
      }
      exportData.push(amazonAmountArray);

      let amazonCountArray = ["Amazonギフト交換件数"];
      for (let j = 0; j < numOfHeaderData; j++) {
        amazonCountArray.push(data[0]["amazonCount"][j]);
      }
      exportData.push(amazonCountArray);

      let goodsAmountArray = ["グッズ交換額"];
      for (let j = 0; j < numOfHeaderData; j++) {
        goodsAmountArray.push(data[0]["goodsPoint"][j]);
      }
      exportData.push(goodsAmountArray);

      let goodsCountArray = ["グッズ交換件数"];
      for (let j = 0; j < numOfHeaderData; j++) {
        goodsCountArray.push(data[0]["goodsCount"][j]);
      }
      exportData.push(goodsCountArray);

      let uniqueCountArray = ["ユニークユーザー数"];
      for (let j = 0; j < numOfHeaderData; j++) {
        uniqueCountArray.push(data[0]["depCount"][j]);
      }
      exportData.push(uniqueCountArray);
    }
    return exportData;
  };

  const getDataTable = (data) => {
    console.log("YOYO data:", data);
    return (
      <>
        <tr>
          {data[0]["depPoint"].map((d, i) => (
            <td className={classes.dataCellTd} key={i}>
              {d}
            </td>
          ))}
        </tr>
        <tr>
          {data[0]["depCount"].map((d, i) => (
            <td className={classes.dataCellTd} key={i}>
              {d}
            </td>
          ))}
        </tr>
        <tr>
          {data[0]["amazonPoint"].map((d, i) => (
            <td className={classes.dataCellTd} key={i}>
              {d}
            </td>
          ))}
        </tr>
        <tr>
          {data[0]["amazonCount"].map((d, i) => (
            <td className={classes.dataCellTd} key={i}>
              {d}
            </td>
          ))}
        </tr>
        <tr>
          {data[0]["goodsPoint"].map((d, i) => (
            <td className={classes.dataCellTd} key={i}>
              {d}
            </td>
          ))}
        </tr>
        <tr>
          {data[0]["goodsCount"].map((d, i) => (
            <td className={classes.dataCellTd} key={i}>
              {d}
            </td>
          ))}
        </tr>
        <tr>
          {data[0]["uniqueCount"].map((d, i) => (
            <td className={classes.dataCellTd} key={i}>
              {d}
            </td>
          ))}
        </tr>
      </>
    );
  };

  const getTableLabelColumn = () => {
    return (
      <>
        <tr>
          <td className={classes.tTd}>
            <div className={classes.lblGameName}>DEP交換額</div>
          </td>
        </tr>
        <tr>
          <td className={classes.tTd}>
            <div className={classes.lblGameName}>DEP交換件数</div>
          </td>
        </tr>
        <tr>
          <td className={classes.tTd}>
            <div className={classes.lblGameName}>Amazonギフト交換額</div>
          </td>
        </tr>
        <tr>
          <td className={classes.tTd}>
            <div className={classes.lblGameName}>Amazonギフト交換件数</div>
          </td>
        </tr>
        <tr>
          <td className={classes.tTd}>
            <div className={classes.lblGameName}>グッズ交換額</div>
          </td>
        </tr>
        <tr>
          <td className={classes.tTd}>
            <div className={classes.lblGameName}>グッズ交換件数</div>
          </td>
        </tr>
        <tr>
          <td className={classes.tTd}>
            <div className={classes.lblGameName}>ユニークユーザー数</div>
          </td>
        </tr>
      </>
    );
  };

  return (
    <C.ContentWrapper>
      <Grid
        container
        direction="row"
        className={classes.sectionTop}
        justifyContent="space-between"
      >
        <Grid item>
          <Breadcrumbs separator="›" className={classes.breadcrumbs}>
            <Typography>PicTrée</Typography>
            <Typography variant="body1">課金</Typography>
            <Typography variant="body1">ポイント交換</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item className={classes.fillRemailSpace}></Grid>
        <Grid item>
          <C.CalendarRangePicker mDate={"2024/04/13"} page="pictree/sales/point" pictree />
        </Grid>
      </Grid>

      <PointStats loading={pageTopLoader} />

      {pictreeView === "sales/point/chart" &&
        <>
          <Grid container spacing={1} className={classes.gridSection}>
            <Grid item xs={12} md={12} lg={12}>
              {pageTopLoader ? (
                <div className={classes.loaderBox}>
                  <img
                    className={classes.loaderBarImg}
                    src={`/static/images/loader-bar.gif`}
                  />
                </div>
              ) : chartState ? (
                pointTrend.length > 0 ? (
                  <LineChartPictreePoint
                    legend={ChartConfig.legendPicTreePointTrend}
                    lblSaveImage={""}
                    xAisData={pointTrend[0]["date"]}
                    seriesData={pointTrend}
                    chartType={"line"}
                  />
                ) : (
                  <div className={classes.selectGameTxt}>
                    <Chip
                      label="No Data"
                      style={{
                        color: V.textColor,
                        backgroundColor: V.placeholderColor,
                      }}
                    />
                  </div>
                )
              ) : null}
            </Grid>
          </Grid>

          <Grid container spacing={1} className={classes.gridSection}>
            <Grid item xs={12} md={12} lg={12}>
              <PicTreeTable
                game="PicTree"
                dataLabel={["DEP交換額", "DEP交換件数", "Amazonギフト交換額", "Amazonギフト交換件数",
                  "グッズ交換額", "グッズ交換件数", "ユニークユーザー数"]}
                data={pointTrend}
                tableKey={["depPoint", "depCount", "amazonPoint", "amazonCount", "goodsPoint", "goodsCount", "uniqueCount"]}
                csvFileName="PicTree - ポイント交換トレンドデータ"
                getExportData={getExportData}
                getDataTable={getDataTable}
                getTableLabelColumn={getTableLabelColumn}
              />
            </Grid>
          </Grid>
        </>
      }
    </C.ContentWrapper>
  );
};

PicTreePointExchange.propTypes = {
  classes: PropTypes.object,
};

const mapStateToProps = (state) => ({
  loading: state.nftDep.loading,
  pageTopLoader: state.page.pageTopLoader,
  pictreeView: state.pictree.pictreeView,
  pointTrend: state.pictree.pointTrend,
  dateRangePicTree: state.page.dateRangePicTree,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentPage: (param) => dispatch(setCurrentPage(param)),
  getPointOverviewDataPicTree: () => dispatch(getPointOverviewDataPicTree()),
  getPointTrendDataPicTree: () => dispatch(getPointTrendDataPicTree()),
  setPictreeView: (param) => dispatch(setPictreeView(param)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(OverviewStyles, { withTheme: true })(PicTreePointExchange));
