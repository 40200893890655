import { put, delay, takeEvery, all, call, select } from "redux-saga/effects";
import _ from "lodash";
import moment from "moment-timezone";
import {
  setPageTopLoader,
  setA2ECampaigns,
  setA2ECampaignSelect,
  setA2EDataSummary,
  setA2EDataByDate,
  setA2ETabDataLoader,
} from "actions";
import { getJwtToken, getDataFromRDS, getDataFromSxi } from "api";
import { A2E, ENDPOINT } from "../../constants";
import * as H from "helper";
import axios from "axios";
import { campaignsData } from '../../utils/mockdata/a2e/Data';

export const currentPage = (state) => state.page.currentPage;
export const dateRange = (state) => state.a2e.customDateRange;
export const a2eAlias = (state) => state.a2e.alias;
export const currentTab = (state) => state.a2e.reportTab;

function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function getRandomFloat(min, max) {
  return (Math.random() * (max - min)) + min;
}

export function* getA2eCampaigns(action) {
  try {
    yield put(setPageTopLoader(true));
    const jwtToken = yield call(getJwtToken);
    const rangeTarget = yield select(dateRange);

    const sqlGetAllCampaigns = {
      // query: `SELECT * FROM answer_target_material atm
      //         JOIN answer_target_material_version	atmv ON atm.id = atmv.answer_target_material_id
      //         JOIN versioned_answer_target_material vatm ON vatm.id = atmv.id
      //         ORDER BY atm.create_datetime DESC`,
      query: `
        SELECT
            atm.*,
            atmv.*,
            vatm.*,
            asmb.*,
            a.id AS answer_service_id,
            a.alias AS answer_service_alias,
            a.start_datetime,
            a.end_datetime,
            ap.alias AS answer_producer_alias
        FROM answer_target_material atm
        JOIN answer_target_material_version atmv ON atm.id = atmv.answer_target_material_id
        JOIN versioned_answer_target_material vatm ON vatm.id = atmv.id
        JOIN answer_service_material_bind asmb ON atm.id = asmb.answer_target_material_id
        JOIN answer_service a ON a.id = asmb.answer_service_id
        JOIN answer_producer ap ON a.answer_producer_id = ap.id
        ORDER BY atm.create_datetime DESC
      `,
      database: "KpiDashboard",
      type: "list",
    }
    //  JOIN campaign_material_bind	cmb ON atm.id = cmb.answer_target_material_id
    // temporarly call halt
    const [dataTempCampains] = yield all([
      call(getDataFromSxi, sqlGetAllCampaigns, ENDPOINT.GET_DATA_SXI_A2E, jwtToken),
    ]);

    // Set xAxisDate
    let recordArray = [];
    const dataToParse = dataTempCampains.body === undefined ? campaignsData : JSON.parse(dataTempCampains.body);
    let eachCampaignData = {};
    // console.log("LOG:::", dataToParse);

    // sort the mock order
    const sortedCampaignsData = _.orderBy(dataToParse, ['create_datetime'], ['desc']);

    _.each(sortedCampaignsData, function(data) {
    // for (let i = 0; i < 74; i++) {
    if (data.alias.indexOf("テスト") === -1) {
      const startDate = moment(data.start_datetime);
      const endDate = moment(data.end_datetime);
      const now = moment.utc(); // Get the current UTC time

      eachCampaignData.status = now.isBefore(endDate) ? "Active" : "Ended";
      eachCampaignData.clientName = data.answer_producer_alias;
      eachCampaignData.name = data.alias; //"CryptoGames_A2Eクイズ_240423";
      eachCampaignData.campaignId = data.answer_target_material_id; //data.answer_service_id; //data.answer_target_material_id;
      eachCampaignData.startDate = moment(startDate).tz("Asia/Tokyo").format('YYYY-MM-DD');
      eachCampaignData.endDate = moment(endDate).tz("Asia/Tokyo").format('YYYY-MM-DD');
      eachCampaignData.duration = endDate.diff(startDate, 'days') + 1;

      // キャンペーンがアクティブの場合、残りの日数を計算
      if (eachCampaignData.status === "Active") {
        eachCampaignData.remainDays = endDate.diff(now, 'days') + 1; // 残りの日数に1日を足す
      } else {
        eachCampaignData.remainDays = 0;
      }

      recordArray.push(eachCampaignData);
      eachCampaignData = {};
    }
    // }
    });

    yield put(setA2ECampaigns(recordArray));
    yield put(setPageTopLoader(false));
  } catch (err) {
    console.log(err);
  }
}

export function* getA2eCampaignMeta(action) {
  try {
    yield put(setPageTopLoader(true));
    const jwtToken = yield call(getJwtToken);
    const rangeTarget = yield select(dateRange);
    // console.log(`GET META DATA for ${action.payload}, range ${rangeTarget}`)
    const sqlGetCampaignById = {
      query: `SELECT
                atm.*,
                atmv.*,
                vatm.*,
                asmb.*,
                a.id AS answer_service_id,
                a.alias AS answer_service_alias,
                a.start_datetime,
                a.end_datetime,
                ap.alias AS answer_producer_alias
              FROM answer_target_material atm
              JOIN answer_target_material_version atmv ON atm.id = atmv.answer_target_material_id
              JOIN versioned_answer_target_material vatm ON vatm.id = atmv.id
              JOIN answer_service_material_bind asmb ON atm.id = asmb.answer_target_material_id
              JOIN answer_service a ON a.id = asmb.answer_service_id
              JOIN answer_producer ap ON a.answer_producer_id = ap.id
              WHERE vatm.answer_target_material_id = '${action.payload}'`,
      database: "KpiDashboard",
      type: "list",
    };

    const [dataTempCampain] = yield all([
      call(getDataFromSxi, sqlGetCampaignById, ENDPOINT.GET_DATA_SXI_A2E, jwtToken),
    ]);
    const campaignSelectedData = [];
    const dataToParse = dataTempCampain.body !== undefined
      ? JSON.parse(dataTempCampain.body)
      : campaignsData;

    dataToParse.filter((obj) => {
      if (obj.answer_target_material_id === action.payload) {
        campaignSelectedData.push(obj);
      }
    });
    console.log("LOG campaignSelectedData:", campaignSelectedData);
    yield put(setA2ECampaignSelect(campaignSelectedData));
    yield put(setPageTopLoader(false));
  } catch (err) {
    console.log(err);
  }
}

export function* getA2eDataSummary(action) {
  try {
    yield put(setPageTopLoader(true));
    const jwtToken = yield call(getJwtToken);
    const rangeTarget = yield select(dateRange);
    const alias = yield select(a2eAlias);
    const reportTab = yield select(currentTab);

    // imp
    const sqlDataImpById = {
      query: `SELECT COUNT(*) AS imp
              FROM distribution d
              INNER JOIN distribution_unit du ON du.distribution_id = d.id
              INNER JOIN distribution_unit_of_quiz_with_ad duoa ON du.id = duoa.id
              JOIN alias_of_question_based_method aoqbm ON aoqbm.question_based_method_version_id = duoa.versioned_quiz_id
              WHERE d.distributed_datetime >= '${rangeTarget[0]} 09:00:00' AND d.distributed_datetime < '${rangeTarget[1]} 09:00:00'
              AND aoqbm.alias = '${alias}'
      `,
      database: "KpiDashboard",
      type: "list",
    };

    // watch start
    const sqlDataWatchById = {
      query: `SELECT COUNT(*) AS start_watch_count
              FROM answer_event_of_started_quiz_with_ad e
              JOIN answer_event abs_e ON e.id = abs_e.id
              JOIN alias_of_question_based_method aoatm ON e.versioned_quiz_id = aoatm.question_based_method_version_id
              WHERE abs_e.occurred_datetime >= '${rangeTarget[0]} 09:00:00' AND abs_e.occurred_datetime < '${rangeTarget[1]} 09:00:00'
              AND aoatm.alias = '${alias}'
      `,
      database: "KpiDashboard",
      type: "list",
    };

    // watch complete
    const sqlDataWatchedById = {
      query: `SELECT COUNT(*) AS complete_watch_count
              FROM answer_event_of_watched_ad_before_quiz e
              JOIN answer_event abs_e on e.id = abs_e.id
              JOIN alias_of_question_based_method aoatm on e.versioned_quiz_id = aoatm.question_based_method_version_id
              WHERE abs_e.occurred_datetime >= '${rangeTarget[0]} 09:00:00' AND abs_e.occurred_datetime < '${rangeTarget[1]} 09:00:00'
              AND aoatm.alias = '${alias}'
      `,
      database: "KpiDashboard",
      type: "list",
    };

    // Quiz Answer
    const sqlDataQuizById = {
      query: `SELECT versioned_quiz_id, aoqbm.alias, COUNT(*) AS answer_count, SUM(CASE WHEN is_correct THEN 1 ELSE 0 END) AS correct_answer_count
              FROM answer_event_of_answered_quiz_with_ad e
              JOIN answer_event abs_e on e.id = abs_e.id
              JOIN alias_of_question_based_method aoqbm on e.versioned_quiz_id = aoqbm.question_based_method_version_id
              WHERE occurred_datetime >= '${rangeTarget[0]} 09:00:00' AND occurred_datetime < '${rangeTarget[1]} 09:00:00'
              AND aoqbm.alias = '${alias}'
      `,
      database: "KpiDashboard",
      type: "list",
    };

    // Banner click
    const sqlDataBannerClickById = {
      query: `SELECT  COUNT(*) AS click_bunner_count
              FROM answer_event_of_click_url_of_quiz_with_ad e
              JOIN answer_event abs_e ON e.id = abs_e.id
              JOIN alias_of_question_based_method aoqbm on e.versioned_quiz_id = aoqbm.question_based_method_version_id
              WHERE abs_e.occurred_datetime >= '${rangeTarget[0]} 09:00:00' AND abs_e.occurred_datetime < '${rangeTarget[1]} 09:00:00'
              AND aoqbm.alias = '${alias}'
      `,
      database: "KpiDashboard",
      type: "list",
    };

    // Hint click
    const sqlDataHintClickById = {
      query: `SELECT COUNT(*) AS click_hint_count
              FROM answer_event_of_click_url_inside_quiz e
              JOIN answer_event abs_e ON e.id = abs_e.id
              JOIN alias_of_question_based_method aoqbm on e.versioned_quiz_id = aoqbm.question_based_method_version_id
              WHERE abs_e.occurred_datetime >= '${rangeTarget[0]} 09:00:00' AND abs_e.occurred_datetime < '${rangeTarget[1]} 09:00:00'
              AND aoqbm.alias = '${alias}'
      `,
      database: "KpiDashboard",
      type: "list",
    };

    const [dataTempImp, dataTempWatch, dataTempWatched, dataTempQuiz, dataTempBannerClick, dataTempHintClick] = yield all([
      call(getDataFromSxi, sqlDataImpById, ENDPOINT.GET_DATA_SXI_A2E, jwtToken),
      call(getDataFromSxi, sqlDataWatchById, ENDPOINT.GET_DATA_SXI_A2E, jwtToken),
      call(getDataFromSxi, sqlDataWatchedById, ENDPOINT.GET_DATA_SXI_A2E, jwtToken),
      call(getDataFromSxi, sqlDataQuizById, ENDPOINT.GET_DATA_SXI_A2E, jwtToken),
      call(getDataFromSxi, sqlDataBannerClickById, ENDPOINT.GET_DATA_SXI_A2E, jwtToken),
      call(getDataFromSxi, sqlDataHintClickById, ENDPOINT.GET_DATA_SXI_A2E, jwtToken)
    ]);


    const dataImp = dataTempImp.body === undefined ? 0 : JSON.parse(dataTempImp.body);
    const dataWatch = dataTempWatch.body === undefined ? 0 : JSON.parse(dataTempWatch.body);
    const dataWatched = dataTempWatched.body === undefined ? 0 : JSON.parse(dataTempWatched.body);
    const dataQuiz = dataTempQuiz.body === undefined ? 0 : JSON.parse(dataTempQuiz.body);
    const dataBannerClick = dataTempBannerClick.body === undefined ? 0 : JSON.parse(dataTempBannerClick.body);
    const dataHintClick = dataTempHintClick.body === undefined ? 0 : JSON.parse(dataTempHintClick.body);

    const summaryData = [{
      "imp": dataImp[0]["imp"],
      "watch": dataWatch[0]["start_watch_count"],
      "watched": dataWatched[0]["complete_watch_count"],
      "answer_count": dataQuiz[0]["answer_count"],
      "correct_answer_count": dataQuiz[0]["correct_answer_count"],
      "click_bunner_count": dataBannerClick[0]["click_bunner_count"],
      "click_hint_count": dataHintClick[0]["click_hint_count"],
    }];
    yield put(setA2EDataSummary(summaryData));

    switch (reportTab) {
      case "byDate":
        yield put({ type: A2E.GET_A2E_DATA_BY_DATE, payload: {} });
        break;
      case "byCreative":
        yield put({ type: A2E.GET_A2E_DATA_BY_CREATIVE, payload: {} });
        break;
      // case "byQuiz":
      //   yield put({ type: A2E.GET_A2E_DATA_BY_QUIZ, payload: {} });
      //   break;
      default:
    }

    yield put(setPageTopLoader(false));
  } catch (err) {
    console.log(err);
  }
}

export function* getA2eDataByDate(action) {
  try {
    yield put(setPageTopLoader(true));
    yield put(setA2ETabDataLoader(true));
    const jwtToken = yield call(getJwtToken);
    const rangeTarget = yield select(dateRange);
    const alias = yield select(a2eAlias);
    // console.log(`GET DATE DATA for ${action.payload}, range ${rangeTarget}`)

    // imp
    const sqlDataImpByDate = {
      query: `SELECT DATE_FORMAT(CONVERT_TZ(d.distributed_datetime, '+00:00', '+09:00'), '%Y-%m-%d') AS event_date_jst, COUNT(*) AS imp
              FROM distribution d
              INNER JOIN distribution_unit du ON du.distribution_id = d.id
              INNER JOIN distribution_unit_of_quiz_with_ad duoa ON du.id = duoa.id
              JOIN alias_of_question_based_method aoqbm ON aoqbm.question_based_method_version_id = duoa.versioned_quiz_id
              WHERE d.distributed_datetime >= '${rangeTarget[0]} 09:00:00' AND d.distributed_datetime < '${rangeTarget[1]} 09:00:00'
              AND aoqbm.alias = '${alias}'
              GROUP BY event_date_jst
      `,
      database: "KpiDashboard",
      type: "list",
    };

    // watch start
    const sqlDataWatchByDate = {
      query: `SELECT DATE_FORMAT(CONVERT_TZ(abs_e.occurred_datetime, '+00:00', '+09:00'), '%Y-%m-%d') AS event_date_jst, COUNT(*) AS start_watch_count
              FROM answer_event_of_started_quiz_with_ad e
              JOIN answer_event abs_e ON e.id = abs_e.id
              JOIN alias_of_question_based_method aoatm ON e.versioned_quiz_id = aoatm.question_based_method_version_id
              WHERE abs_e.occurred_datetime >= '${rangeTarget[0]} 09:00:00' AND abs_e.occurred_datetime < '${rangeTarget[1]} 09:00:00'
              AND aoatm.alias = '${alias}'
              GROUP BY event_date_jst
      `,
      database: "KpiDashboard",
      type: "list",
    };

    // watch complete
    const sqlDataWatchedByDate = {
      query: `SELECT DATE_FORMAT(CONVERT_TZ(abs_e.occurred_datetime, '+00:00', '+09:00'), '%Y-%m-%d') AS event_date_jst, COUNT(*) AS complete_watch_count
              FROM answer_event_of_watched_ad_before_quiz e
              JOIN answer_event abs_e on e.id = abs_e.id
              JOIN alias_of_question_based_method aoatm on e.versioned_quiz_id = aoatm.question_based_method_version_id
              WHERE abs_e.occurred_datetime >= '${rangeTarget[0]} 09:00:00' AND abs_e.occurred_datetime < '${rangeTarget[1]} 09:00:00'
              AND aoatm.alias = '${alias}'
              GROUP BY event_date_jst
      `,
      database: "KpiDashboard",
      type: "list",
    };

    // Quiz Answer
    const sqlDataQuizByDate = {
      query: `SELECT
                DATE_FORMAT(CONVERT_TZ(abs_e.occurred_datetime, '+00:00', '+09:00'), '%Y-%m-%d') AS event_date_jst,
                COUNT(*) AS answer_count,
                SUM(CASE WHEN is_correct THEN 1 ELSE 0 END) AS correct_answer_count
              FROM answer_event_of_answered_quiz_with_ad e
              JOIN answer_event abs_e on e.id = abs_e.id
              JOIN alias_of_question_based_method aoqbm on e.versioned_quiz_id = aoqbm.question_based_method_version_id
              WHERE abs_e.occurred_datetime >= '${rangeTarget[0]} 09:00:00' AND abs_e.occurred_datetime < '${rangeTarget[1]} 09:00:00'
              AND aoqbm.alias = '${alias}'
              GROUP BY event_date_jst
      `,
      database: "KpiDashboard",
      type: "list",
    };

    // Banner click
    const sqlDataBannerClickByDate = {
      query: `SELECT DATE_FORMAT(CONVERT_TZ(abs_e.occurred_datetime, '+00:00', '+09:00'), '%Y-%m-%d') AS event_date_jst, COUNT(*) AS click_bunner_count
              FROM answer_event_of_click_url_of_quiz_with_ad e
              JOIN answer_event abs_e ON e.id = abs_e.id
              JOIN alias_of_question_based_method aoqbm on e.versioned_quiz_id = aoqbm.question_based_method_version_id
              WHERE abs_e.occurred_datetime >= '${rangeTarget[0]} 09:00:00' AND abs_e.occurred_datetime < '${rangeTarget[1]} 09:00:00'
              AND aoqbm.alias = '${alias}'
              GROUP BY event_date_jst
      `,
      database: "KpiDashboard",
      type: "list",
    };

    // Hint click
    const sqlDataHintClickByDate = {
      query: `SELECT DATE_FORMAT(CONVERT_TZ(abs_e.occurred_datetime, '+00:00', '+09:00'), '%Y-%m-%d') AS event_date_jst, COUNT(*) AS click_hint_count
              FROM answer_event_of_click_url_inside_quiz e
              JOIN answer_event abs_e ON e.id = abs_e.id
              JOIN versioned_material_method_bind b ON b.question_based_method_version_id = e.versioned_quiz_id
              JOIN alias_of_question_based_method aoqbm on e.versioned_quiz_id = aoqbm.question_based_method_version_id
              WHERE abs_e.occurred_datetime >= '${rangeTarget[0]} 09:00:00' AND abs_e.occurred_datetime < '${rangeTarget[1]} 09:00:00'
              AND aoqbm.alias = '${alias}'
              GROUP BY event_date_jst
      `,
      database: "KpiDashboard",
      type: "list",
    };

    const [dataTempImp, dataTempWatch, dataTempWatched, dataTempQuiz, dataTempBannerClick, dataTempHintClick] = yield all([
      call(getDataFromSxi, sqlDataImpByDate, ENDPOINT.GET_DATA_SXI_A2E, jwtToken),
      call(getDataFromSxi, sqlDataWatchByDate, ENDPOINT.GET_DATA_SXI_A2E, jwtToken),
      call(getDataFromSxi, sqlDataWatchedByDate, ENDPOINT.GET_DATA_SXI_A2E, jwtToken),
      call(getDataFromSxi, sqlDataQuizByDate, ENDPOINT.GET_DATA_SXI_A2E, jwtToken),
      call(getDataFromSxi, sqlDataBannerClickByDate, ENDPOINT.GET_DATA_SXI_A2E, jwtToken),
      call(getDataFromSxi, sqlDataHintClickByDate, ENDPOINT.GET_DATA_SXI_A2E, jwtToken)
    ]);

    const dataImpParsed = dataTempImp.body === undefined ? 0 : JSON.parse(dataTempImp.body);
    const dataWatchParsed = dataTempWatch.body === undefined ? 0 : JSON.parse(dataTempWatch.body);
    const dataWatchedParsed = dataTempWatched.body === undefined ? 0 : JSON.parse(dataTempWatched.body);
    const dataQuizParsed = dataTempQuiz.body === undefined ? 0 : JSON.parse(dataTempQuiz.body);
    const dataBannerClickParsed = dataTempBannerClick.body === undefined ? 0 : JSON.parse(dataTempBannerClick.body);
    const dataHintClickParsed = dataTempHintClick.body === undefined ? 0 : JSON.parse(dataTempHintClick.body);

    // Set xAxisDate
    let xAxisDate = [], dataImpArray = [], dataWatchArray = [], dataWatchRatioArray = [], dataWatchedArray = [], dataWatchedRatioArray = [],
    dataQuizAnswerArray = [], dataQuizCorrectArray = [], dataQuizCorrectRatioArray = [], dataBannerClickArray = [], dataBannerClickRatioArray = [], dataHintClickArray = [], dataHintClickRatioArray = [], recordArray = [];

    const daysArray = H.FormatDateHelper.getDaysListBetweenDays(
      rangeTarget[0],
      rangeTarget[1]
    );
    _.each(daysArray, function (data) {
      xAxisDate.push(data);
    });

    xAxisDate.forEach((date) => {
      // imp
      const dataImp = _.find(
        dataImpParsed,
        (item) => _.get(item, "event_date_jst") === date
      );
      dataImpArray.push(dataImp ? _.get(dataImp, "imp") : 0);
      // watch
      const dataWatch = _.find(
        dataWatchParsed,
        (item) => _.get(item, "event_date_jst") === date
      );
      dataWatchArray.push(dataWatch ? _.get(dataWatch, "start_watch_count") : 0);

      // watch Ratio
      const impData = _.get(dataImp, "imp");
      const watchData = _.get(dataWatch, "start_watch_count");
      let watchRatioData = "0%";
      if (impData !== undefined && watchData !== undefined) {
        watchRatioData = `${(watchData / impData * 100).toFixed(2)}%`
      }
      dataWatchRatioArray.push(watchRatioData);

      // watched
      const dataWatched = _.find(
        dataWatchedParsed,
        (item) => _.get(item, "event_date_jst") === date
      );
      dataWatchedArray.push(dataWatched ? _.get(dataWatched, "complete_watch_count") : 0);

      // watched Ratio
      const watchedData = _.get(dataWatched, "complete_watch_count");
      let watchedRatioData = "0%";
      if (watchedData !== undefined) {
        watchedRatioData = `${(watchedData / watchData * 100).toFixed(2)}%`
      }
      dataWatchedRatioArray.push(watchedRatioData);

      // quiz - answer
      const dataQuizAnswer = _.find(
        dataQuizParsed,
        (item) => _.get(item, "event_date_jst") === date
      );
      dataQuizAnswerArray.push(dataQuizAnswer ? _.get(dataQuizAnswer, "answer_count") : 0);
      dataQuizCorrectArray.push(dataQuizAnswer ? _.get(dataQuizAnswer, "correct_answer_count") : 0);

      // quiz - correct ratio
      const quizAnswerCount =  _.get(dataQuizAnswer, "answer_count");
      const quizCorrectCount = _.get(dataQuizAnswer, "correct_answer_count")
      let correctRatioData = "0%";
      if (quizCorrectCount !== undefined) {
        correctRatioData = `${(quizCorrectCount / quizAnswerCount * 100).toFixed(2)}%`
      }
      dataQuizCorrectRatioArray.push(correctRatioData);

      // hint
      const dataHint = _.find(
        dataHintClickParsed,
        (item) => _.get(item, "event_date_jst") === date
      );
      dataHintClickArray.push(dataHint ? _.get(dataHint, "click_hint_count") : 0);

      // hintClick ratio
      const hintClickedCount =  _.get(dataHint, "click_hint_count");
      let hintRatioData = "0%";
      if (hintClickedCount !== undefined) {
        hintRatioData = `${(hintClickedCount / quizAnswerCount * 100).toFixed(2)}%`
      }
      dataHintClickRatioArray.push(hintRatioData);

      // banner
      const dataBanner = _.find(
        dataBannerClickParsed,
        (item) => _.get(item, "event_date_jst") === date
      );
      dataBannerClickArray.push(dataBanner ? _.get(dataBanner, "click_bunner_count") : 0);

      // banner click ratio
      const bannerClickedCount =  _.get(dataBanner, "click_bunner_count");
      let bannerRatioData = "0%";
      if (bannerClickedCount !== undefined) {
        bannerRatioData = `${(bannerClickedCount / quizAnswerCount * 100).toFixed(2)}%`
      }
      dataBannerClickRatioArray.push(bannerRatioData);

    });

    const allDataArray = {
      date: xAxisDate,
      imp: dataImpArray,
      watch: dataWatchArray,
      watchRatio: dataWatchRatioArray,
      watched: dataWatchedArray,
      watchedRatio: dataWatchedRatioArray,
      quizAnswer: dataQuizAnswerArray,
      quizCorrect: dataQuizCorrectArray,
      correctRatio: dataQuizCorrectRatioArray,
      hintClick: dataHintClickArray,
      hintRatio: dataHintClickRatioArray,
      bannerClick: dataBannerClickArray,
      bannerRatio: dataBannerClickRatioArray,
    };
    recordArray.push(allDataArray);
    yield put(setA2EDataByDate(recordArray));
    yield put(setPageTopLoader(false));
    yield put(setA2ETabDataLoader(false));
  } catch (err) {
    console.log(err);
  }
}

export function* getA2eDataByCreative(action) {
  try {
    yield put(setPageTopLoader(true));
    yield put(setA2ETabDataLoader(true));
    const jwtToken = yield call(getJwtToken);
    const rangeTarget = yield select(dateRange);
    const alias = yield select(a2eAlias);
    console.log(`GET CREATIVE DATA for ${alias}, range ${rangeTarget}`)

    // imp
    const sqlDataImpByCreative = {
      query: `SELECT duoa.versioned_ad_id, aoatm.alias, COUNT(d.id) AS ad_imp
              FROM distribution d
              INNER JOIN distribution_unit du ON du.distribution_id = d.id
              INNER JOIN distribution_unit_of_quiz_with_ad duoa ON du.id = duoa.id
              JOIN versioned_answer_target_material vatm ON vatm.answer_target_material_id = duoa.versioned_ad_id
              WHERE d.distributed_datetime >= '${rangeTarget[0]} 09:00:00' AND d.distributed_datetime < '${rangeTarget[1]} 09:00:00'
              AND vatm.alias = '${alias}'
              GROUP BY duoa.versioned_ad_id
      `,
      database: "KpiDashboard",
      type: "list",
    };

    // watch
    const sqlDataWatchByCreative = {
      query: `SELECT versioned_ad_id, aoatm.alias, COUNT(*) AS quiz_ad_view_count
              FROM answer_event_of_started_quiz_with_ad e
              JOIN answer_event abs_e ON e.id = abs_e.id
              JOIN versioned_answer_target_material vatm ON e.versioned_ad_id = vatm.answer_target_material_id
              WHERE abs_e.occurred_datetime >= '${rangeTarget[0]} 09:00:00' AND abs_e.occurred_datetime < '${rangeTarget[1]} 09:00:00'
              AND vatm.alias = '${alias}'
              GROUP BY versioned_ad_id
      `,
      database: "KpiDashboard",
      type: "list",
    };

    // watched
    const sqlDataWatchedByCreative = {
      query: `SELECT versioned_ad_id, aoatm.alias, COUNT(*) AS quiz_ad_complete_view_count
              FROM answer_event_of_watched_ad_before_quiz e
              JOIN answer_event abs_e on e.id = abs_e.id
              JOIN versioned_answer_target_material vatm ON e.versioned_ad_id = vatm.answer_target_material_id
              WHERE occurred_datetime >= '${rangeTarget[0]} 09:00:00' AND occurred_datetime < '${rangeTarget[1]} 09:00:00'
              AND vatm.alias = '${alias}'
              GROUP BY versioned_ad_id
      `,
      database: "KpiDashboard",
      type: "list",
    };

    const [dataTempImp, dataTempWatch, dataTempWatched] = yield all([
      call(getDataFromSxi, sqlDataImpByCreative, ENDPOINT.GET_DATA_SXI_A2E, jwtToken),
      call(getDataFromSxi, sqlDataWatchByCreative, ENDPOINT.GET_DATA_SXI_A2E, jwtToken),
      call(getDataFromSxi, sqlDataWatchedByCreative, ENDPOINT.GET_DATA_SXI_A2E, jwtToken),
    ]);
    const dataImp = dataTempImp.body === undefined ? 0 : JSON.parse(dataTempImp.body);
    const dataWatch = dataTempWatch.body === undefined ? 0 : JSON.parse(dataTempWatch.body);
    const dataWatched = dataTempWatched.body === undefined ? 0 : JSON.parse(dataTempWatched.body);
    console.log(`DATA for dataImp:`, dataImp);
    console.log(`DATA for dataWatch:`, dataWatch);
    console.log(`DATA for dataWatched:`, dataWatched);

    // assign data
    let dataImpArray = [], dataWatchArray = [], dataWatchedArray = [], recordArray = [];

    yield put(setPageTopLoader(false));
  } catch (err) {
    console.log(err);
  }
}

export function* getA2eDataByQuiz(action) {
  try {
    yield put(setPageTopLoader(true));
    const jwtToken = yield call(getJwtToken);
    const rangeTarget = yield select(dateRange);
    const alias = yield select(a2eAlias);
    console.log(`GET QUIZ DATA for ${alias}, range ${rangeTarget}`)

    // imp
    const sqlDataImpByQuiz = {
      query: `SELECT duoa.versioned_quiz_id, aoqbm.alias, COUNT(d.id) AS imp
              FROM distribution d
              INNER JOIN distribution_unit du ON du.distribution_id = d.id
              INNER JOIN distribution_unit_of_quiz_with_ad duoa ON du.id = duoa.id
              JOIN alias_of_question_based_method aoqbm ON aoqbm.question_based_method_version_id = duoa.versioned_quiz_id
              WHERE d.distributed_datetime >= '${rangeTarget[0]} 09:00:00' AND d.distributed_datetime < '${rangeTarget[1]} 09:00:00'
              AND aoqbm.alias = '${alias}'
              GROUP BY duoa.versioned_quiz_id
      `,
      database: "KpiDashboard",
      type: "list",
    };

    // watch
    const sqlDataWatchByQuiz = {
      query: `SELECT versioned_quiz_id, aoatm.alias, COUNT(*) AS quiz_ad_view_count
              FROM answer_event_of_started_quiz_with_ad e
              JOIN answer_event abs_e on e.id = abs_e.id
              JOIN alias_of_question_based_method aoatm on e.versioned_quiz_id = aoatm.question_based_method_version_id
              WHERE abs_e.occurred_datetime >= '${rangeTarget[0]} 09:00:00' AND abs_e.occurred_datetime < '${rangeTarget[1]} 09:00:00'
              AND aoatm.alias = '${alias}'
              GROUP BY duoa.versioned_quiz_id
      `,
      database: "KpiDashboard",
      type: "list",
    };

    // watched
    const sqlDataWatchedByQuiz = {
      query: `SELECT versioned_ad_id, aoatm.alias, COUNT(*) AS quiz_ad_complete_view_count
              FROM answer_event_of_watched_ad_before_quiz e
              JOIN answer_event abs_e on e.id = abs_e.id
              JOIN alias_of_question_based_method aoatm on e.versioned_quiz_id = aoatm.question_based_method_version_id
              WHERE occurred_datetime >= '${rangeTarget[0]} 09:00:00' AND occurred_datetime < '${rangeTarget[1]} 09:00:00'
              AND aoatm.alias = '${alias}'
              GROUP BY duoa.versioned_quiz_id
      `,
      database: "KpiDashboard",
      type: "list",
    };

    // quiz
    const sqlDataAnswerByQuiz = {
      query: `SELECT versioned_quiz_id, aoqbm.alias, COUNT(*) AS answer_count, SUM(CASE WHEN is_correct THEN 1 ELSE 0 END) AS correct_answer_count
              FROM answer_event_of_answered_quiz_with_ad e
              JOIN answer_event abs_e on e.id = abs_e.id
              JOIN alias_of_question_based_method aoqbm on e.versioned_quiz_id = aoqbm.question_based_method_version_id
              WHERE occurred_datetime >= '${rangeTarget[0]} 09:00:00' AND occurred_datetime < '${rangeTarget[1]} 09:00:00'
              AND aoqbm.alias = '${alias}'
              GROUP BY duoa.versioned_quiz_id
      `,
      database: "KpiDashboard",
      type: "list",
    };

    // banner
    const sqlDataBannerByQuiz = {
      query: `SELECT versioned_quiz_id, aoqbm.alias, COUNT(*) AS quiz_click_count
              FROM answer_event_of_click_url_of_quiz_with_ad e
              JOIN answer_event abs_e on e.id = abs_e.id
              JOIN alias_of_question_based_method aoqbm on e.versioned_quiz_id = aoqbm.question_based_method_version_id
              WHERE occurred_datetime >= '${rangeTarget[0]} 09:00:00' AND occurred_datetime < '${rangeTarget[1]} 09:00:00'
              AND aoqbm.alias = '${alias}'
              GROUP BY versioned_quiz_id;
      `,
      database: "KpiDashboard",
      type: "list",
    };

    // hint
    const sqlDataHintByQuiz = {
      query: `SELECT versioned_quiz_id, aoqbm.alias, COUNT(*) AS hint_click_count
              FROM answer_event_of_click_url_inside_quiz e
              JOIN answer_event abs_e on e.id = abs_e.id
              JOIN alias_of_question_based_method aoqbm on e.versioned_quiz_id = aoqbm.question_based_method_version_id
              WHERE occurred_datetime >= '${rangeTarget[0]} 09:00:00' AND occurred_datetime < '${rangeTarget[1]} 09:00:00'
              AND aoqbm.alias = '${alias}'
              GROUP BY versioned_quiz_id;
      `,
      database: "KpiDashboard",
      type: "list",
    };

    const dataTempImp = yield call(getDataFromSxi, sqlDataImpByQuiz, ENDPOINT.GET_DATA_SXI_A2E, jwtToken);
    const dataTempWatch = yield call(getDataFromSxi, sqlDataWatchByQuiz, ENDPOINT.GET_DATA_SXI_A2E, jwtToken);
    const dataTempWatched = yield call(getDataFromSxi, sqlDataWatchedByQuiz, ENDPOINT.GET_DATA_SXI_A2E, jwtToken);
    const dataTempQuiz = yield call(getDataFromSxi, sqlDataAnswerByQuiz, ENDPOINT.GET_DATA_SXI_A2E, jwtToken);
    const dataTempBanner = yield call(getDataFromSxi, sqlDataBannerByQuiz, ENDPOINT.GET_DATA_SXI_A2E, jwtToken);
    const dataTempHint = yield call(getDataFromSxi, sqlDataHintByQuiz, ENDPOINT.GET_DATA_SXI_A2E, jwtToken);
    console.log(`Quiz DATA for dataTempImp:`, dataTempImp);
    console.log(`Quiz DATA for dataTempWatch:`, dataTempWatch);
    console.log(`Quiz DATA for dataTempWatched:`, dataTempWatched);
    console.log(`Quiz DATA for dataTempQuiz:`, dataTempQuiz);
    console.log(`Quiz DATA for dataTempBanner:`, dataTempBanner);
    console.log(`Quiz DATA for dataTempHint:`, dataTempHint);

    // assign data
    let dataImpArray = [], dataWatchArray = [], dataWatchedArray = [], recordArray = [];


    yield put(setPageTopLoader(false));
  } catch (err) {
    console.log(err);
  }
}

export default function* watchPageSettings() {
  yield takeEvery(A2E.GET_A2E_CAMPAIGNS, getA2eCampaigns);
  yield takeEvery(A2E.GET_A2E_CAMPAIGN_META_BY_ID, getA2eCampaignMeta);
  yield takeEvery(A2E.GET_A2E_DATA_SUMMARY, getA2eDataSummary);
  yield takeEvery(A2E.GET_A2E_DATA_BY_DATE, getA2eDataByDate);
  yield takeEvery(A2E.GET_A2E_DATA_BY_CREATIVE, getA2eDataByCreative);
  // yield takeEvery(A2E.GET_A2E_DATA_BY_QUIZ, getA2eDataByQuiz);
}
