import { put, delay, takeEvery, all, call, select } from "redux-saga/effects";
import _ from "lodash";
import moment from "moment-timezone";
import { setPageTopLoader, setPictreeAdSummary, setPictreeAdTrend } from "actions";
import { getJwtToken, getDataFromRDS, getDataFromSxi } from "api";
import { PICTREE, ENDPOINT } from "../../constants";
import * as H from "helper";
import axios from 'axios';

export const currentPage = (state) => state.page.currentPage;
export const dateRange = (state) => state.page.dateRangePicTree;
export const dateRangeNtt = (state) => state.page.dateRangePicTreeNtt;
export const selectedSeason = state => state.pictree.selectedSeason;

export function* getPictreeAdSummary(action) {
  try {
    yield put(setPageTopLoader(true));
    const jwtToken = yield call(getJwtToken);
    const rangeTarget = yield select(dateRange);

    let sqlQueryAdImpression = {
      query: `
        SELECT COUNT(*) AS Impression
        FROM DenchuGameAdImpressionProd
        WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo')
        BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'`,
      database: "KpiDashboard",
      type: "list",
    };

    let sqlQueryAdClick = {
      query: `
        SELECT COUNT(*) AS Click
        FROM DenchuGameAdClickProd
        WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo')
        BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'`,
      database: "KpiDashboard",
      type: "list",
    };

    let sqlQueryAdImpressionUnique = {
      query: `
        SELECT COUNT(DISTINCT deviceId) AS Impression
        FROM DenchuGameAdImpressionProd
        WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo')
        BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'`,
      database: "KpiDashboard",
      type: "list",
    };

    let sqlQueryAdClickUnique = {
      query: `
        SELECT COUNT(DISTINCT deviceId) AS Click
        FROM DenchuGameAdClickProd
        WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo')
        BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'`,
      database: "KpiDashboard",
      type: "list",
    };

    const [dataTempImp, dataTempClick, dataTempImpUni, dataTempClickUni] = yield all([
     call(getDataFromRDS, sqlQueryAdImpression, ENDPOINT.GET_DATA_USERS, jwtToken),
     call(getDataFromRDS, sqlQueryAdClick, ENDPOINT.GET_DATA_USERS, jwtToken),
     call(getDataFromRDS, sqlQueryAdImpressionUnique, ENDPOINT.GET_DATA_USERS, jwtToken),
     call(getDataFromRDS, sqlQueryAdClickUnique, ENDPOINT.GET_DATA_USERS, jwtToken),
    ]);
    const dataImp = JSON.parse(dataTempImp.body);
    const dataClick= JSON.parse(dataTempClick.body);
    const dataImpUni = JSON.parse(dataTempImpUni.body);
    const dataClickUni= JSON.parse(dataTempClickUni.body);

    const adData = {
      impression: dataImp[0][0]["longValue"],
      click: dataClick[0][0]["longValue"],
      ctr: dataImp[0][0]["longValue"] > 0 && dataClick[0][0]["longValue"] > 0
        ? ((dataClick[0][0]["longValue"] / dataImp[0][0]["longValue"]) * 100).toFixed(2)
        : 0,
      impressionUni: dataImpUni[0][0]["longValue"],
      clickUni: dataClickUni[0][0]["longValue"],
      ctrUni: dataImpUni[0][0]["longValue"] > 0 && dataClickUni[0][0]["longValue"] > 0
        ? ((dataClickUni[0][0]["longValue"] / dataImpUni[0][0]["longValue"]) * 100).toFixed(2)
        : 0,
    };

    yield put(setPictreeAdSummary(adData));
    yield put(setPageTopLoader(false));
  } catch (err) {
    console.log(err);
  }
}

export function* getPictreeDataCheckInTrendSxi(action) {
  try {
    yield put(setPageTopLoader(true));
    const jwtToken = yield call(getJwtToken);
    const rangeTarget = yield select(dateRange);
    const selectedSeasonData = yield select(selectedSeason);

    if (!rangeTarget || rangeTarget.length !== 2) {
      console.error("Invalid rangeTarget");
      yield put(setPageTopLoader(false));
      return;
    }

    if (!selectedSeasonData || selectedSeasonData.length === 0) {
      console.error("No seasons selected");
      yield put(setPageTopLoader(false));
      return;
    }
    const seasonIdList = selectedSeasonData.map(season => `'${season.seasonId}'`).join(", ");

    let sqlQueryCheckinTrend = {
      query: `
        SELECT DATE_FORMAT(CONVERT_TZ(pae.occurred_datetime, 'UTC', 'Asia/Tokyo'), '%Y-%m-%d') AS DAY,
        COUNT(*) AS CHECKIN_COUNT,
        COUNT(DISTINCT player_id) AS PLAYER_COUNT
        FROM power_asset_event pae
        WHERE CONVERT_TZ(pae.occurred_datetime, 'UTC', 'Asia/Tokyo')
        BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59' AND pae.type in ("CHECK_IN")
        AND game_space_id IN (${seasonIdList})
        GROUP BY DATE(CONVERT_TZ(pae.occurred_datetime, 'UTC', 'Asia/Tokyo'))
      `,
      database: "KpiDashboard",
      type: "list",
    };

    const [trendTemp] = yield all([
      call(
        getDataFromSxi,
        sqlQueryCheckinTrend,
        ENDPOINT.GET_DATA_SXI_DENCHU,
        jwtToken
      ),
    ]);

    // Set xAxisDate
    let xAxisDate = [], dataImpArray = [], dataClickArray = [], recordArray = [];
    const daysArray = action.payload === "day"
      ? H.FormatDateHelper.getDaysListBetweenDays(rangeTarget[0], rangeTarget[1])
      : H.FormatDateHelper.getHoursListBetweenDays(rangeTarget[0], rangeTarget[1]);
    _.each(daysArray, function(data) {
        xAxisDate.push(data);
    });

    const rangeValue = action.payload === "day" ? "DAY" : "HOUR";
    xAxisDate.forEach(date => {
      const data = _.find(trendTemp, item => _.get(item, rangeValue) === date);
      if (data) {
        dataImpArray.push(_.get(data, 'CHECKIN_COUNT'));
      } else {

        dataImpArray.push(0);
      }
      const uniqueData = _.find(trendTemp, item => _.get(item, rangeValue) === date);
      if (uniqueData) {
        dataClickArray.push(_.get(uniqueData, 'PLAYER_COUNT'));
      } else {
        dataClickArray.push(0);
      }
    });

    const checkInCountData = {
      date: xAxisDate,
      count: dataImpArray,
      unique: dataClickArray,
  };
    recordArray.push(checkInCountData);

    yield put(setPictreeAdTrend(recordArray));
    yield put(setPageTopLoader(false));
  } catch (err) {
    console.log(err);
  }
}

export function* getPictreeAdTrend(action) {
  try {
    yield put(setPageTopLoader(true));
    const jwtToken = yield call(getJwtToken);
    const rangeTarget = yield select(dateRange);

    const sqlQueryAdImpression = {
      query: `
        SELECT DATE(convert_tz(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo')) AS DAY, COUNT(*) AS Impression
        FROM DenchuGameAdImpressionProd
        WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo')
        BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
        GROUP BY DATE(convert_tz(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo'))
        ORDER BY logDate ASC;`,
      database: "KpiDashboard",
      type: "list",
    };

    const sqlQueryAdImpressionUni = {
      query: `
        SELECT DATE(convert_tz(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo')) AS DAY, COUNT(DISTINCT deviceId) AS Impression
        FROM DenchuGameAdImpressionProd
        WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo')
        BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
        GROUP BY DATE(convert_tz(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo'))
        ORDER BY logDate ASC;`,
      database: "KpiDashboard",
      type: "list",
    };

    const sqlQueryAdClick = {
      query: `
        SELECT DATE(convert_tz(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo')) AS DAY, COUNT(*) AS Click
        FROM DenchuGameAdClickProd
        WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo')
        BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
        GROUP BY DATE(convert_tz(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo'))
        ORDER BY logDate ASC;`,
      database: "KpiDashboard",
      type: "list",
    };

    const sqlQueryAdClickUni = {
      query: `
        SELECT DATE(convert_tz(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo')) AS DAY, COUNT(DISTINCT deviceId) AS Click
        FROM DenchuGameAdClickProd
        WHERE CONVERT_TZ(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo')
        BETWEEN '${rangeTarget[0]} 00:00:00' AND '${rangeTarget[1]} 23:59:59'
        GROUP BY DATE(convert_tz(FROM_UNIXTIME(logDate / 1000), 'UTC', 'Asia/Tokyo'))
        ORDER BY logDate ASC;`,
      database: "KpiDashboard",
      type: "list",
    };

    const [dataTempImpTrend, dataTempClickTrend, dataTempImpTrendUni, dataTempClickTrendUni] = yield all([
     call(getDataFromRDS, sqlQueryAdImpression, ENDPOINT.GET_DATA_USERS, jwtToken),
     call(getDataFromRDS, sqlQueryAdClick, ENDPOINT.GET_DATA_USERS, jwtToken),
     call(getDataFromRDS, sqlQueryAdImpressionUni, ENDPOINT.GET_DATA_USERS, jwtToken),
     call(getDataFromRDS, sqlQueryAdClickUni, ENDPOINT.GET_DATA_USERS, jwtToken),
    ]);
    const dataImpTrend = JSON.parse(dataTempImpTrend.body);
    const dataClickTrend = JSON.parse(dataTempClickTrend.body);
    const dataImpTrendUni = JSON.parse(dataTempImpTrendUni.body);
    const dataClickTrendUni = JSON.parse(dataTempClickTrendUni.body);

    // Set xAxisDate
    let xAxisDate = [], dataImpArray = [], dataClickArray = [], dataImpUniArray = [], dataClickUniArray = [], recordArray = [];
    const daysArray = H.FormatDateHelper.getDaysListBetweenDays(rangeTarget[0], rangeTarget[1]);
    _.each(daysArray, function(data) {
        xAxisDate.push(data);
    });

    xAxisDate.forEach(date => {
      // Check if the date exists in the data array
      const data = _.find(dataImpTrend, item => _.get(item, '[0].stringValue') === date);
      if (data) {
        // console.log(`Date: ${date}, Value: ${_.get(data, '[1].longValue')}`);
        dataImpArray.push(_.get(data, '[1].longValue'));
      } else {
        // console.log(`Date: ${date}, No data available`);
        dataImpArray.push(0);
      }
      const uniqueData = _.find(dataClickTrend, item => _.get(item, '[0].stringValue') === date);
      if (uniqueData) {
        dataClickArray.push(_.get(uniqueData, '[1].longValue'));
      } else {
        dataClickArray.push(0);
      }
      // unique impression
      const uniqueImpData = _.find(dataImpTrendUni, item => _.get(item, '[0].stringValue') === date);
      if (uniqueImpData) {
        dataImpUniArray.push(_.get(uniqueImpData, '[1].longValue'));
      } else {
        dataImpUniArray.push(0);
      }
      // unique click
      if (dataClickTrendUni) {
        const uniqueClickData = _.find(dataClickTrendUni, item => _.get(item, '[0].stringValue') === date);
        if (uniqueClickData) {
          dataClickUniArray.push(_.get(uniqueClickData, '[1].longValue'));
        } else {
          dataClickUniArray.push(0);
        }
      }
    });

    const adData = {
      date: xAxisDate,
      impression: dataImpArray,
      click: dataClickArray,
      impressionUni: dataImpUniArray,
      clickUni: dataClickUniArray,
    };
    recordArray.push(adData);

    yield put(setPictreeAdTrend(recordArray));
    yield put(setPageTopLoader(false));
  } catch (err) {
    console.log(err);
  }
}

export default function* watchPageSettings() {
  yield takeEvery(PICTREE.GET_AD_SUMMARY, getPictreeAdSummary);
  yield takeEvery(PICTREE.GET_AD_TREND, getPictreeAdTrend);
}
